import { ClusteredTasksResponse, TicketsSearchResponse } from 'fetchers/responses/tickets.response';
import { WorkQueueResponse } from 'fetchers/responses/work-queue.response';

import { parseDate } from 'utils/DateUtils';

import CallbackTicket from 'models/CallbackTicket';

import ClusteredTaskTicket from 'models/ClusteredTaskTicket';
import OperatorTicket from 'models/OperatorTicket';
import TaskTicket from 'models/TaskTicket';
import Ticket, { TicketClass } from 'models/Ticket';
import { TicketHistoryAudit, AuditActions, Clinician } from 'models/TicketHistoryAudit';

import { WorkQueueItemType } from 'views/WorkQueue/WorkQueue.types';

import PatientParser from './PatientParser';

export class TicketsParser {
  static parseTicket(unparsedTicket: any) {
    const ticket = new Ticket(unparsedTicket);

    if (ticket.class === TicketClass.operator) {
      ticket.operatorTicket = TicketsParser.parseOperatorTicket(unparsedTicket);
    }

    if (ticket.class === TicketClass.callbackRequest) {
      ticket.callbackTicket = TicketsParser.parseCallbackTicket(unparsedTicket);
    }

    return ticket;
  }

  static parseWorkQueueTicket(item: { itemData: any; itemType: any }) {
    const { itemData, itemType } = item;

    if (itemType === WorkQueueItemType.TaskTicket) {
      return TicketsParser.parseTaskTicket(itemData);
    }

    if (itemType === WorkQueueItemType.TaskTicketClustered) {
      return TicketsParser.parseClusteredTaskTicket(itemData);
    }

    const ticket = new Ticket(itemData);

    if (ticket.class === TicketClass.operator) {
      ticket.operatorTicket = TicketsParser.parseOperatorTicket(itemData);
    }

    if (ticket.class === TicketClass.callbackRequest) {
      ticket.callbackTicket = TicketsParser.parseCallbackTicket(itemData);
    }

    return ticket;
  }

  static parseCallbackTicket(unparsedCallbackTicket: any) {
    return new CallbackTicket(unparsedCallbackTicket);
  }

  static parseOperatorTicket(unparsedOperatorTicket: any) {
    return new OperatorTicket(
      unparsedOperatorTicket.ticketId,
      unparsedOperatorTicket.reporter,
      unparsedOperatorTicket.patientContactId,
      unparsedOperatorTicket.contactHippaAuthDate,
      unparsedOperatorTicket.notes,
      unparsedOperatorTicket.providerId,
      unparsedOperatorTicket.locationId,
      unparsedOperatorTicket.ticketTypeId,
      unparsedOperatorTicket.ticketSubTypesIds,
      unparsedOperatorTicket.urgency,
      unparsedOperatorTicket.createdAt,
      unparsedOperatorTicket.updatedAt,
      unparsedOperatorTicket.source,
      unparsedOperatorTicket.ticketTypeDisplayName,
      unparsedOperatorTicket.pathwayAnswers
    );
  }

  static parseTaskTickets(unparsedTaskTickets: any): Ticket[] {
    return unparsedTaskTickets.map((unparsedTaskTicket: any) =>
      TicketsParser.parseTaskTicket(unparsedTaskTicket)
    );
  }

  static parseTaskTicket(unparsedTaskTicket: any) {
    const taskTicket = new TaskTicket(unparsedTaskTicket);
    const ticket = TicketsParser.parseTicket({
      ...unparsedTaskTicket,
      id: unparsedTaskTicket.ticketId,
      ticketClass: TicketClass.task
    });
    ticket.taskTicket = taskTicket;

    return ticket;
  }

  static parseClusteredResponse(unparsedClusteredResponse: ClusteredTasksResponse) {
    const { clusteredTasksMetadata, assignedToMeTasks } = unparsedClusteredResponse;

    const clusteredTasks = clusteredTasksMetadata.map((unparsedClusteredTaskTicket) =>
      TicketsParser.parseClusteredTaskTicket(unparsedClusteredTaskTicket)
    );

    const tasks = assignedToMeTasks.map((unparsedClusteredTaskTicket) =>
      TicketsParser.parseTaskTicket(unparsedClusteredTaskTicket)
    );

    return {
      clusteredTasks,
      tasks
    };
  }

  static parseClusteredTaskTicket(unparsedClusteredTaskTicket: any) {
    const singleTask = unparsedClusteredTaskTicket.singleTask
      ? TicketsParser.parseTaskTicket(unparsedClusteredTaskTicket.singleTask)
      : null;
    return new ClusteredTaskTicket(unparsedClusteredTaskTicket, singleTask);
  }

  static parseSearchTicketsResult({
    patientTickets,
    operatorTickets,
    callbackRequestTickets
  }: TicketsSearchResponse) {
    return [...patientTickets, ...operatorTickets, ...callbackRequestTickets].map(
      TicketsParser.parseTicket
    );
  }

  static parseWorkQueue(workQueueResponse: WorkQueueResponse) {
    Object.values(workQueueResponse.sections).forEach((section) => {
      section.items.forEach((item: any) => {
        if (item.itemType !== WorkQueueItemType.OverdueReport) {
          item.itemData = TicketsParser.parseWorkQueueTicket(item);
        }
      });
    });

    workQueueResponse.patients = workQueueResponse.patients.map(PatientParser.parsePatient);

    return workQueueResponse;
  }

  static parseResolveTicketsResponse(response: any) {
    return {
      optedOutErrorTicketIds: response.optedOutErrorTicketIds || [],
      generalErrorTicketIds: response.generalErrorTicketIds || [],
      resolvedTicketIds: response.resolvedTicketIds || [],
      pdfFailedTicketIds: response.pdfFailedTicketIds || [],
      pdfFailedCallIds: response.pdfFailedCallIds || [],
      pdfSucceededTicketIds: response.pdfSucceededTicketIds || [],
      pdfSucceededCallIds: response.pdfSucceededCallIds || [],
      createdCommentsCount: response.createdCommentsCount || 0
    };
  }

  static parseHistory(
    unparsedAudits: {
      id: number;
      action: AuditActions;
      actionDetails: any;
      performedByClinician: Clinician;
      oldClinician?: Clinician;
      newClinician?: Clinician;
      createdAt: string;
    }[]
  ): TicketHistoryAudit[] {
    return unparsedAudits.map(
      (unparsedAudit) =>
        new TicketHistoryAudit({
          id: unparsedAudit.id,
          action: unparsedAudit.action,
          actionDetails: unparsedAudit.actionDetails,
          performedByClinician: unparsedAudit.performedByClinician,
          oldClinician: unparsedAudit.oldClinician,
          newClinician: unparsedAudit.newClinician,
          createdAt: parseDate(unparsedAudit.createdAt)
        })
    );
  }
}
