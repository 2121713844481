// @ts-strict-ignore
import { FC, ReactNode } from 'react';

import { observer } from 'mobx-react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { CustomSentryCategories, reportBreadcrumb } from 'services/sentryService';

import { ExtendedTicket } from 'utils/TicketClusteringUtils';

import { TicketSectionId } from 'views/WorkQueue/WorkQueue.types';

import { CardRow } from 'components/Surfaces/Card';
import CallbackRequestTicketRow from 'components/Ticket/TicketRow/CallbackRequestTicketRow';
import OperatorTicketRow from 'components/Ticket/TicketRow/OperatorTicketRow';
import SymptomOperatorTicketRow from 'components/Ticket/TicketRow/SymptomOperatorTicketRow';
import TaskTicketRow from 'components/Ticket/TicketRow/TaskTicketRow';
import TicketReportRow from 'components/Ticket/TicketRow/TicketReportRow';
import { useIsInPatientRoute } from 'components/Ticket/TicketsContainers/useIsInPatientRoute';

import './TicketsList.scss';

enum RowType {
  Report,
  Task,
  Operator,
  SymptomOperator,
  CallbackRequest
}

interface TicketItemProps {
  extendedTicket: ExtendedTicket;
  shouldLinkToPatient: boolean;
  ticketRefsCallback?: (node: HTMLElement, ticketId: number) => void;
  ticketIndex: number;
  ticketSectionId: TicketSectionId;
}

const TicketItem: FC<TicketItemProps> = observer(
  ({ extendedTicket, shouldLinkToPatient, ticketRefsCallback, ticketIndex, ticketSectionId }) => {
    const { isOperatorTicket, isSymptomOperatorTicket, isCallbackRequestTicket } =
      extendedTicket.ticket;
    let type = RowType.Report;

    if (isOperatorTicket) {
      type = isSymptomOperatorTicket ? RowType.SymptomOperator : RowType.Operator;
    } else if (extendedTicket.ticket.isTask) {
      type = RowType.Task;
    } else if (isCallbackRequestTicket) {
      type = RowType.CallbackRequest;
    }

    switch (type) {
      case RowType.Operator:
        return (
          <OperatorTicketRow
            key={extendedTicket.ticket.id}
            ticket={extendedTicket.ticket}
            withPatientLink={shouldLinkToPatient}
            ticketRefs={ticketRefsCallback}
            ticketIndex={ticketIndex}
            ticketSectionId={ticketSectionId}
          />
        );
      case RowType.SymptomOperator:
        return (
          <SymptomOperatorTicketRow
            key={extendedTicket.ticket.id}
            ticket={extendedTicket.ticket}
            withPatientLink={shouldLinkToPatient}
            ticketRefsCallback={ticketRefsCallback}
            ticketIndex={ticketIndex}
            ticketSectionId={ticketSectionId}
          />
        );
      case RowType.Task:
        return (
          <CardRow>
            <TaskTicketRow
              key={extendedTicket.ticket.id}
              ticket={extendedTicket.ticket}
              withPatientLink={shouldLinkToPatient}
              ticketRefsCallback={ticketRefsCallback}
              ticketIndex={ticketIndex}
              ticketSectionId={ticketSectionId}
            />
          </CardRow>
        );
      case RowType.Report:
        return (
          <TicketReportRow
            key={extendedTicket.ticket.id}
            ticket={extendedTicket.ticket}
            reports={extendedTicket.reports}
            withPatientLink={shouldLinkToPatient}
            ticketRefs={ticketRefsCallback}
            ticketIndex={ticketIndex}
            ticketSectionId={ticketSectionId}
          />
        );

      case RowType.CallbackRequest:
        return (
          <CallbackRequestTicketRow
            key={extendedTicket.ticket.id}
            ticket={extendedTicket.ticket}
            withPatientLink={shouldLinkToPatient}
            ticketRefsCallback={ticketRefsCallback}
            ticketIndex={ticketIndex}
            ticketSectionId={ticketSectionId}
          />
        );
    }
  }
);

interface TicketsListProps {
  tickets: ExtendedTicket[];
  emptyState?: ReactNode;
  ticketRefsCallback?: (node: HTMLElement, ticketId: number) => void;
  sectionId?: TicketSectionId;
}

const TicketsList: FC<TicketsListProps> = ({
  tickets,
  emptyState,
  ticketRefsCallback,
  sectionId
}) => {
  const isInPatientPage = useIsInPatientRoute();
  reportBreadcrumb({
    level: 'info',
    message: 'render tickets list',
    category: CustomSentryCategories.DEBUG,
    data: {
      tickets: tickets.map((ticket) => ticket.ticket.id)
    }
  }); // Remove when EH-2265 solved
  return (
    <div className="tickets-list">
      {tickets?.length === 0 && emptyState}
      <TransitionGroup>
        {tickets?.map((extendedTicket, index) => {
          return (
            <CSSTransition
              key={extendedTicket.ticket.id}
              classNames="connect-ticket-transition"
              timeout={600}
            >
              <TicketItem
                extendedTicket={extendedTicket}
                shouldLinkToPatient={!isInPatientPage}
                ticketRefsCallback={ticketRefsCallback}
                ticketIndex={index}
                ticketSectionId={sectionId}
              />
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    </div>
  );
};

export default observer(TicketsList);
