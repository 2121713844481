import { TicketType } from 'tests/models/components/ticket/ticket.types';

import { TicketSectionId } from 'views/WorkQueue/WorkQueue.types';

export const ticketSelectors = {
  container: (id: number, type: TicketType, section: TicketSectionId) =>
    `ticket-container-${id}-${type}-${section}`,
  actions: {
    mainButton: (ticketId: number) => `assignedActions${ticketId}`,
    getActionButton: (type: string, ticketId: number) => `${type}-ticket-${ticketId}-action-button`,
    menu: 'ticket-actions-menu',
    pathwayOption: function (pathwayId?: string) {
      if (pathwayId) {
        return `${pathwayId}-option`;
      }

      return 'no-pathway-option';
    },
    reassignOption: function (doctorId?: number) {
      if (doctorId) {
        return `doctor-${doctorId}-option`;
      }

      return 'unassigned-option';
    }
  }
};
