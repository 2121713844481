import { FC, Fragment, MouseEvent } from 'react';

import { AnalyticEventAction } from 'analytics';
import { trackActionButtonAnalyticsEvent } from 'analytics/events/action-button';
import { trackSectionUsageAnalyticsEvent } from 'analytics/events/section-usage';

import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { sharedTestSelectors } from 'tests/shared/shared.selectors';

import { useStores } from 'mobx/hooks/useStores';

import { FEATURES } from 'constants/features';

import { useWqOpenItemsSection } from 'hooks/useWqOpenItemsSection';

import { StyledSectionActionsContainer } from 'views/WorkQueue/WorkQueue.styled';
import {
  WorkQueueItemType,
  TicketSectionId,
  WorkQueueTabName
} from 'views/WorkQueue/WorkQueue.types';

import WqCollapsibleSection from 'views/WorkQueue/WorkQueueOpenItemsView/WqCollapsibleSection';
import { WqSectionControl } from 'views/WorkQueue/WorkQueueOpenItemsView/WqSectionControl';
import { WqTicketPlaceholder } from 'views/WorkQueue/WqTicketPlaceholder';

import OperatorTicketRow from 'components/Ticket/TicketRow/OperatorTicketRow';
import SymptomOperatorTicketRow from 'components/Ticket/TicketRow/SymptomOperatorTicketRow';
import TicketReportRow from 'components/Ticket/TicketRow/TicketReportRow';
import { Section } from 'components/Ticket/TicketsContainers/TicketsContainers.constants';
import { OutlinedButton } from 'components/UIkit/atoms/Button';

const WqUrgentSection: FC = () => {
  const { settingsStore, workQueueStore } = useStores();
  const { totalPages, totalItems, items, currentPage, isSectionPageLoading } =
    useWqOpenItemsSection(TicketSectionId.UrgentPatientReports);
  const history = useHistory();

  const handleTriageClicked = () => {
    trackActionButtonAnalyticsEvent({ action: AnalyticEventAction.TriageRules });
    history.push(`/alerts`);
  };

  const isButtonVisible = settingsStore.hasFeature(FEATURES.TRIAGE_RULES);

  return (
    <div className="report-section">
      <WqCollapsibleSection
        sectionName={Section.Urgent}
        totalItems={totalItems}
        onTrigger={(isOpen) =>
          trackSectionUsageAnalyticsEvent({
            action: isOpen ? AnalyticEventAction.Collapse : AnalyticEventAction.Expand,
            value: Section.Urgent,
            page_number: currentPage,
            tab: WorkQueueTabName.OpenItems
          })
        }
        testHook={sharedTestSelectors.ticketSection.container(TicketSectionId.UrgentPatientReports)}
      >
        <StyledSectionActionsContainer>
          {isButtonVisible && (
            <OutlinedButton
              onClick={(event: MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                handleTriageClicked();
              }}
            >
              Triage Rules
            </OutlinedButton>
          )}

          {totalPages > 1 && (
            <WqSectionControl sectionName={TicketSectionId.UrgentPatientReports} />
          )}
        </StyledSectionActionsContainer>

        <div className="tickets-list">
          <TransitionGroup>
            {items.map(({ itemType, itemData }, index) => {
              const patient = workQueueStore.getPatientModel(itemData.patientId);

              return (
                <CSSTransition
                  key={itemData.id}
                  classNames="connect-ticket-transition"
                  timeout={600}
                >
                  <Fragment>
                    {isSectionPageLoading ? (
                      <WqTicketPlaceholder />
                    ) : (
                      <>
                        {itemType === WorkQueueItemType.OperatorTicket &&
                          !itemData.isSymptomOperatorTicket && (
                            <OperatorTicketRow
                              ticket={itemData}
                              withPatientLink
                              ticketIndex={index}
                              ticketSectionCurrentPage={currentPage - 1}
                              ticketSectionId={TicketSectionId.UrgentPatientReports}
                            />
                          )}

                        {itemType === WorkQueueItemType.OperatorTicket &&
                          itemData.isSymptomOperatorTicket && (
                            <SymptomOperatorTicketRow
                              ticket={itemData}
                              withPatientLink
                              ticketIndex={index}
                              ticketSectionCurrentPage={currentPage - 1}
                              ticketSectionId={TicketSectionId.UrgentPatientReports}
                            />
                          )}

                        {itemType === WorkQueueItemType.PatientTicket && (
                          <TicketReportRow
                            ticket={itemData}
                            reports={patient!.reportsSortedBySeverity}
                            withPatientLink
                            ticketIndex={index}
                            ticketSectionCurrentPage={currentPage - 1}
                            ticketSectionId={TicketSectionId.UrgentPatientReports}
                          />
                        )}
                      </>
                    )}
                  </Fragment>
                </CSSTransition>
              );
            })}
          </TransitionGroup>
        </div>

        {totalPages > 1 && (
          <WqSectionControl sectionName={TicketSectionId.UrgentPatientReports} isBottom />
        )}
      </WqCollapsibleSection>
    </div>
  );
};

export default observer(WqUrgentSection);
