import { CallbackRequestTicket } from 'tests/models/components/ticket/callback-request-ticket';
import { OperatorTicket } from 'tests/models/components/ticket/operator-ticket';
import { ReportTicket } from 'tests/models/components/ticket/report-ticket';
import { SymptomOperatorTicket } from 'tests/models/components/ticket/symptom-operator-ticket';

export enum TicketType {
  Operator = 'operator',
  SymptomOperator = 'symptomOperator',
  Patient = 'patient',
  CallbackRequest = 'callbackRequest',
  Task = 'task',
  Overdue = 'overdue'
}

export enum TicketActionTypeId {
  LogCall = 'log-call',
  SendMessage = 'send-message',
  Reassign = 'reassign',
  Resolve = 'resolve',
  Edit = 'edit',
  ResolveAndComment = 'resolve-and-comment',
  PickUp = 'pick-up',
  Snooze = 'snooze',
  SmsReminder = 'sms-reminder',
  AutomatedReportCall = 'automated-report-call'
}

interface BaseTicketAction {
  elementSelectorToWaitFor?: string;
  type: TicketActionTypeId;
}

interface SendMessageTicketAction {
  type: TicketActionTypeId.SendMessage;
}

export interface BaseLogCallTicketAction {
  type: TicketActionTypeId.LogCall;
}

export interface LogCallTicketAction extends BaseLogCallTicketAction {
  pathwayId?: string; // if no pathway id -> Call Without Pathway
}

export interface ReassignTicketAction {
  type: TicketActionTypeId.Reassign;
  doctorId?: number; // if no doctor id -> Unassign
}

interface ResolveTicketAction {
  type: TicketActionTypeId.Resolve;
}

interface EditTicketAction {
  type: TicketActionTypeId.Edit;
}

interface ResolvedAndCommentTicketAction {
  type: TicketActionTypeId.ResolveAndComment;
}

interface PickUpTicketAction {
  type: TicketActionTypeId.PickUp;
}

export interface SnoozeTicketAction {
  type: TicketActionTypeId.Snooze;
}

export interface SmsReminderTicketAction {
  type: TicketActionTypeId.SmsReminder;
}

export interface AutomatedReportCallTicketAction {
  type: TicketActionTypeId.AutomatedReportCall;
}

export type TicketAction<Action = TicketActionType> = BaseTicketAction & Action;

type OperatorTicketActionType =
  | LogCallTicketAction
  | SendMessageTicketAction
  | ReassignTicketAction
  | ResolveTicketAction
  | EditTicketAction
  | ResolvedAndCommentTicketAction
  | PickUpTicketAction;

type SymptomOperatorTicketActionType =
  | LogCallTicketAction
  | SendMessageTicketAction
  | ReassignTicketAction
  | ResolveTicketAction
  | EditTicketAction
  | ResolvedAndCommentTicketAction
  | PickUpTicketAction;

type ReportTicketActionType =
  | LogCallTicketAction
  | SendMessageTicketAction
  | ReassignTicketAction
  | ResolveTicketAction
  | ResolvedAndCommentTicketAction
  | PickUpTicketAction;

type CallbackRequestTicketActionType =
  | LogCallTicketAction
  | SendMessageTicketAction
  | ReassignTicketAction
  | ResolveTicketAction
  | ResolvedAndCommentTicketAction
  | PickUpTicketAction;

type OverdueReportTicketActionType =
  | BaseLogCallTicketAction
  | SnoozeTicketAction
  | SmsReminderTicketAction
  | AutomatedReportCallTicketAction;

export type OperatorTicketAction = TicketAction<OperatorTicketActionType>;
export type SymptomOperatorTicketAction = TicketAction<SymptomOperatorTicketActionType>;
export type ReportTicketAction = TicketAction<ReportTicketActionType>;
export type CallbackRequestTicketAction = TicketAction<CallbackRequestTicketActionType>;
export type OverdueTicketAction = TicketAction<OverdueReportTicketActionType>;

export type TicketWithActionsButton =
  | OperatorTicket
  | SymptomOperatorTicket
  | ReportTicket
  | CallbackRequestTicket;

export type TicketActionViaActionsButton =
  | OperatorTicketAction
  | SymptomOperatorTicketAction
  | ReportTicketAction
  | CallbackRequestTicketAction;

export type TicketActionType =
  | OperatorTicketAction
  | SymptomOperatorTicketAction
  | ReportTicketAction
  | CallbackRequestTicketAction
  | OverdueTicketAction;
