// @ts-strict-ignore
import { FC, useMemo } from 'react';

import { Box, css, styled } from '@mui/material';

import { observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';

import { sharedTestSelectors } from 'tests/shared/shared.selectors';

import useNetworkLoading from 'mobx/hooks/useNetworkLoading';
import { useStores } from 'mobx/hooks/useStores';

import { ExtendedTicket } from 'utils/TicketClusteringUtils';

import { removeUrlParam } from 'utils/urlUtils';

import { API_LABELS } from 'constants/apiUrls';

import Ticket from 'models/Ticket';

import { HIGHLIGHT_TICKET_QUERY_PARAM } from 'views/Patient/PatientMain/PatientMainView.constants';

import { CollapsibleSection } from 'views/Widgets/CollapsibleSection';
import ToggleBar, { TabOption } from 'views/Widgets/ToggleBar';

import { TicketSectionId } from 'views/WorkQueue/WorkQueue.types';

import FixedLoader from 'components/Loaders/FixedLoader';
import TicketsList from 'components/Ticket/TicketsContainers/TicketsList';
import { useSectionExpand } from 'components/Ticket/TicketsContainers/useTicketOverviewContext';

import { Section } from './TicketsContainers.constants';
import TicketsSectionTasksDueFilters from './TicketsSectionTasksDueFilters';

import './TicketsSectionTasksDue.scss';

const TASKS_VIEW_TABS = {
  TIME_RANGE: 'Time Range View',
  MONTHLY: 'Monthly View'
};

const tabOptions: TabOption<string>[] = [
  { value: TASKS_VIEW_TABS.TIME_RANGE, label: TASKS_VIEW_TABS.TIME_RANGE },
  { value: TASKS_VIEW_TABS.MONTHLY, label: TASKS_VIEW_TABS.MONTHLY }
];

interface Props {
  sectionTitle?: string;
  tasks: Ticket[];
  withFilters?: boolean;
  timeViewToggle?: boolean;
  ticketRefsCallback?: (node: HTMLElement, ticketId: number) => void;
}

interface TaskSectionInfo {
  title: string;
  tickets?: ExtendedTicket[];
}

const StyledWrapper = styled(Box)(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: ${theme.spacing(20)};
  `
);

const TicketsSectionTasksDue: FC<Props> = ({
  sectionTitle = Section.TasksDue,
  tasks,
  withFilters = false,
  timeViewToggle = false,
  ticketRefsCallback
}) => {
  const { tasksStore, ticketFiltersStore } = useStores();
  const history = useHistory();
  const location = useLocation();
  const isLoadingTasks = useNetworkLoading(API_LABELS.TASKS_QUERY(false));
  const [isExpanded, setIsExpanded] = useSectionExpand(Section.TasksDue);

  const content = useMemo((): TaskSectionInfo => {
    return {
      title: `${sectionTitle} ${!tasksStore.updatingTasks ? `(${tasks.length})` : ''}`,
      tickets: tasks.map((ticket: Ticket) => ({ ticket }))
    };
  }, [sectionTitle, tasks, tasksStore.updatingTasks]);

  const { title, tickets } = content;

  const activeTab = tasksStore.viewByMonths ? TASKS_VIEW_TABS.MONTHLY : TASKS_VIEW_TABS.TIME_RANGE;

  const emptyState = isLoadingTasks ? null : (
    <div className="tasks-empty-state">No tasks for the selected dates / filters. </div>
  );

  const header = <div className="d-flex flex-column">{title}</div>;

  const onFromDateChanged = (date: Date | null) => {
    ticketFiltersStore.setQueryFromDate(date);
    tasksStore.fetchTasks();
  };

  const onToDateChanged = (date: Date | null) => {
    ticketFiltersStore.setQueryToDate(date);
    tasksStore.fetchTasks();
  };

  const extraActions = (
    <div className="d-flex align-items-center">
      {withFilters && (
        <TicketsSectionTasksDueFilters
          onToDateChanged={onToDateChanged}
          onFromDateChanged={onFromDateChanged}
        />
      )}

      {withFilters && timeViewToggle && <VerticalLine />}

      {timeViewToggle && (
        <StyledToggleBar
          id="tasksMonthViewToggle"
          options={tabOptions}
          selected={activeTab}
          onOptionSelected={() => {
            removeUrlParam(history, location.search, HIGHLIGHT_TICKET_QUERY_PARAM);
            tasksStore.toggleViewByMonths();
          }}
          isSquared
          size="small"
        />
      )}
    </div>
  );

  return (
    <div className="report-section">
      <CollapsibleSection
        testHook={sharedTestSelectors.ticketSection.container(TicketSectionId.TasksDue)}
        name={Section.TasksDue}
        trigger={
          <div className="report-section-header" data-test-hook={`${sectionTitle}_header`}>
            {header}
          </div>
        }
        onTrigger={setIsExpanded}
        initiallyOpen={isExpanded}
      >
        <StyledWrapper>{extraActions}</StyledWrapper>
        {isLoadingTasks && <FixedLoader />}
        <TicketsList
          tickets={tickets}
          emptyState={emptyState}
          ticketRefsCallback={ticketRefsCallback}
          sectionId={TicketSectionId.TasksDue}
        />
      </CollapsibleSection>
    </div>
  );
};

const StyledToggleBar = styled(ToggleBar)(
  ({ theme }) => css`
    margin-left: ${theme.spacing(12)};
  `
);

const VerticalLine = styled(Box)(
  ({ theme }) => css`
    width: 1px;
    height: 45px;
    background-color: ${theme.palette.natural.border};
    margin-left: ${theme.spacing(12)};
  `
);

export default observer(TicketsSectionTasksDue);
