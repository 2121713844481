import { FC } from 'react';

import { Box, css } from '@mui/material';
import { styled } from '@mui/material/styles';
import { OptionProps } from 'react-select';

import { isSubStringCaseInsensitive } from 'utils/StringUtils';

import { useVerticalOverflowDetector } from 'hooks/useVerticalOverflowDetector';

import { SimpleTooltip } from 'components/Tooltip';

import { Highlighter } from 'components/Tooltip/Highlighter';
import { LabeledCheckbox } from 'components/UIkit/atoms/Checkbox';
import { getCustomSelectCheckboxOptionChildren } from 'components/UIkit/atoms/Dropdown/Select/CustomOptions/CustomSelectCheckboxOption.utils';
import { StyledMenuOption } from 'components/UIkit/atoms/Dropdown/Select/ReactSelectOptionComponent';
import { ISelectOption } from 'components/UIkit/atoms/Dropdown/Select.shared';
import { Text } from 'components/UIkit/atoms/Text';

export const CustomSelectCheckboxOption: FC<OptionProps<ISelectOption<number>, true>> = (props) => {
  const { ref, isActive } = useVerticalOverflowDetector();
  const { selectProps, data } = props;
  const { value, label, isParent } = data;
  const { inputValue, options } = selectProps;
  const selectValue = (selectProps.value || []) as ISelectOption<number>[];
  const { isAllChildrenChecked, isAllChildrenUnchecked } = getCustomSelectCheckboxOptionChildren(
    value as number,
    options as ISelectOption<number>[],
    selectValue
  );
  const isDisabled = isParent ? !isSubStringCaseInsensitive(label.trim(), inputValue) : false;
  const isSelected = isParent ? isAllChildrenChecked : props.isSelected;

  return (
    <StyledMenuOption {...props}>
      <StyledOption isFocused={props.isFocused}>
        <LabeledCheckbox
          variant="tertiary"
          key={value}
          label={
            <SimpleTooltip
              arrow={false}
              placement="right"
              title={
                <Box py={2} px={4}>
                  {label}
                </Box>
              }
              disabled={!isActive}
            >
              <Text maxNumberOfLines={2} ref={ref} variant="body2">
                <Highlighter searchValue={inputValue} textToHighlight={label} />
              </Text>
            </SimpleTooltip>
          }
          checked={isSelected}
          indeterminate={isParent ? !isAllChildrenChecked && !isAllChildrenUnchecked : false}
          pl={isParent ? 4 : 24}
          disabled={isDisabled}
          alignItems="flex-start"
        />
      </StyledOption>
    </StyledMenuOption>
  );
};

export const StyledOption = styled(Box)<{ isFocused: boolean }>(
  ({ theme, isFocused }) =>
    css`
      ${isFocused &&
      css`
        .MuiCheckbox-root {
          div {
            border-color: ${theme.palette.secondary.dark};
          }

          &.Mui-checked,
          &.MuiCheckbox-indeterminate {
            div {
              background-color: ${theme.palette.secondary.dark};
              border-color: ${theme.palette.secondary.dark};
            }
          }
        }
      `}
    `
);
