// @ts-strict-ignore
import { compact, omit, sortBy } from 'lodash/fp';

import {
  IOperatorTicketsCreateRequest,
  IOperatorTicketUpdateRequest
} from 'fetchers/TicketsFetcher';

import { PathwayQuestionTypes } from 'models/PathwayTemplates';

import Patient from 'models/Patient';

import {
  LightweightPathwayDependentQuestionAnswer,
  LightweightPathwayMainQuestionAnswer,
  LightweightPathwayQuestionAnswer,
  MultipleValue
} from 'components/LightweightPathway/LightweightPathway.types';

import {
  IOperatorCreateTicketsFormFields,
  IOperatorUpdateTicketFormFields,
  NEW_CONTACT_VALUE,
  PATIENT_IS_CONTACT_VALUE,
  TicketFormField
} from './ticket.shared';

function createContactInfo(
  ticketsData: IOperatorUpdateTicketFormFields | IOperatorCreateTicketsFormFields
) {
  const phoneNumber = ticketsData.countryCode + ticketsData.phone;
  let contactInfo;
  if (ticketsData.contactId !== PATIENT_IS_CONTACT_VALUE) {
    contactInfo = {
      id: ticketsData.contactId === NEW_CONTACT_VALUE ? null : parseInt(ticketsData.contactId),
      name: ticketsData.callbackName,
      phoneNumber,
      hippaAuthDate: ticketsData.callbackHippaAuth ? new Date() : null,
      relationship: ticketsData.callbackRelationship.value
    };
  }
  return contactInfo;
}

const createFormattedLightweightPathwaysAnswers = (
  ticketLightweightPathwaysAnswers: LightweightPathwayQuestionAnswer[] = []
) => {
  const sortedLightweightPathwaysAnswers = sortBy(
    ['pathway.index', 'index'],
    ticketLightweightPathwaysAnswers
  );

  const formattedLightweightPathwaysAnswers: (
    | Omit<LightweightPathwayMainQuestionAnswer, 'index'>
    | Omit<LightweightPathwayDependentQuestionAnswer, 'index' | 'parentQuestionId'>
  )[] = [];

  sortedLightweightPathwaysAnswers.forEach((answer) => {
    let newAnswerValue = answer.value;

    if (answer.type === PathwayQuestionTypes.MULTIPLE) {
      newAnswerValue = sortBy(
        'index',
        (answer as LightweightPathwayQuestionAnswer<MultipleValue>).value
      );
    }

    const questionWithoutClientProperties = {
      ...(omit(['index', 'parentQuestionId'], answer) as Omit<
        LightweightPathwayDependentQuestionAnswer,
        'index' | 'parentQuestionId'
      >)
    };

    formattedLightweightPathwaysAnswers.push({
      ...questionWithoutClientProperties,
      value: newAnswerValue,
      pathway: { ...omit('index', answer.pathway) }
    });
  });

  return formattedLightweightPathwaysAnswers;
};

const extractTicketFormData = (ticket: TicketFormField) => ({
  urgency: ticket.urgency.value,
  notes: ticket.notes,
  providerId: ticket.providerId.value.id,
  locationId: ticket.locationId.value.id,
  assigneeId: ticket.assignee ? ticket.assignee.value : null,
  ticketTypeId: Number(ticket.categoryId),
  ticketSubTypeIds: ticket.ticketSubTypeIds.map(Number),
  lightweightPathwaysAnswers: createFormattedLightweightPathwaysAnswers(
    ticket.lightweightPathwaysAnswers
  ),
  lightweightPathwaySummaries: ticket.lightweightPathwaySummaries || []
});

export function buildTicketsCreateRequest(
  ticketsData: IOperatorCreateTicketsFormFields,
  patient: Patient
): IOperatorTicketsCreateRequest {
  const contactInfo = createContactInfo(ticketsData);
  const tickets = compact(Object.values<TicketFormField>(ticketsData.tickets)).map(
    extractTicketFormData
  );

  return {
    tickets,
    patientId: patient.id,
    contactInfo
  };
}

export function buildTicketUpdateRequest(
  ticketsData: IOperatorUpdateTicketFormFields,
  patient: Patient
): IOperatorTicketUpdateRequest {
  const contactInfo = createContactInfo(ticketsData);

  return {
    ticket: extractTicketFormData(ticketsData.ticket),
    patientId: patient.id,
    contactInfo
  };
}
