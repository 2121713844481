import { ENV } from 'constants/config';

import Pendo = pendo.Pendo;

export class PendoService {
  static initPendo(institutionId: number, clinicianId: number) {
    (pendo as Pendo).initialize({
      visitor: {
        id: String(clinicianId), // Required if user is logged in, default creates anonymous ID
        // email:        // Recommended if using Pendo Feedback, or NPS Email
        // full_name:    // Recommended if using Pendo Feedback
        // role:         // Optional

        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
        institutionId: String(institutionId),
        environment: ENV
      },

      account: {
        id: String(institutionId) // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
        // name:         // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional

        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
      }
    });
  }
}
