// @ts-strict-ignore
import React, { ChangeEvent, FC, FocusEvent, MutableRefObject, ReactNode, useState } from 'react';

import Box from '@mui/material/Box';
import classNames from 'classnames';

import { Testable } from 'utils/TypeUtils';

import Icon from 'components/Icons/Icon';
import { Highlighter } from 'components/Tooltip/Highlighter';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { TooltipController, TooltipTrigger } from 'components/Tooltip/Tooltip.types';

import { LabeledCheckbox } from 'components/UIkit/atoms/Checkbox/LabeledCheckbox';

import { Text } from 'components/UIkit/atoms/Text';

import './TooltipContent.scss';

export interface ITooltipOption extends Testable {
  text: ReactNode;
  id?: string;
  isVisible?: boolean;
  disabled?: boolean;
  isSelected?: boolean;
  readonly?: boolean;
  withDivider?: boolean;
  extraText?: string;
  groupHeader?: string;
  dividerPlacement?: DividerPlacement;
  onClick?: (value?: any) => void;
  hasHighlightOnText?: boolean;
  className?: string;
}

export interface TooltipSelectProps {
  children?: ReactNode;
  options: ITooltipOption[];
  title?: string;
  onSearchChange?: (value?: any) => void;
  onSearchBlur?: (
    event: FocusEvent<HTMLInputElement>,
    currentValue: string,
    valueAfterFocus: string
  ) => void;
  searchPlaceholder?: string;
  className?: string;
  searchValue?: string;
  id?: string;
  variant?: 'primary' | 'secondary';
}

export enum DividerPlacement {
  Top = 'top',
  Bottom = 'bottom'
}

export const TooltipSelect: FC<TooltipSelectProps> = ({
  options,
  title,
  onSearchChange,
  onSearchBlur,
  children,
  searchPlaceholder,
  searchValue: controlledSearchValue,
  id,
  className,
  variant = 'primary'
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [valueAfterFocus, setValueAfterFocus] = useState('');

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);
    onSearchChange(value);
  };

  const optionContainerClassName = classNames(
    'options-container',
    { 'with-header': title || onSearchChange },
    { 'with-footer': children },
    className
  );
  const selectedIconSize = variant === 'secondary' ? 9 : 12;

  return (
    <div className="tooltip-select" id={id}>
      {title && <div className="tooltip-select-title mb-2">{title}</div>}
      {onSearchChange && (
        <div className="dropdown-search py-2 px-3">
          <Icon.Search />
          <input
            type="text"
            name="name"
            placeholder={searchPlaceholder}
            className="search-input"
            onFocus={() => setValueAfterFocus(searchValue)}
            onBlur={(e) => onSearchBlur && onSearchBlur(e, searchValue, valueAfterFocus)}
            autoComplete="off"
            onChange={handleSearchChange}
            value={searchValue}
          />
        </div>
      )}
      <div className={optionContainerClassName}>
        {options.map(
          (
            {
              id,
              onClick,
              testHook,
              isSelected,
              disabled,
              text,
              extraText,
              isVisible = true,
              withDivider,
              groupHeader,
              dividerPlacement,
              readonly,
              hasHighlightOnText = false,
              className
            },
            index
          ) => {
            if (!isVisible) {
              return null;
            }
            return (
              <React.Fragment key={`option_${id || index}`}>
                {groupHeader && (
                  <Box py={4}>
                    <Text px={8} variant="body2" color="disabled">
                      {groupHeader.toUpperCase()}
                    </Text>
                  </Box>
                )}
                <div
                  onClick={!disabled ? onClick : null}
                  data-test-hook={testHook}
                  className={classNames('tooltip-select-option', {
                    secondary: variant === 'secondary',
                    [className]: !!className,
                    selected: isSelected,
                    disabled: disabled,
                    readonly,
                    'with-divider': withDivider,
                    'divider-placement-top': dividerPlacement === DividerPlacement.Top,
                    'divider-placement-bottom': dividerPlacement === DividerPlacement.Bottom
                  })}
                >
                  <span className="checkmark">
                    <Icon.CheckMark height={selectedIconSize} width={selectedIconSize} />
                  </span>
                  {hasHighlightOnText ? (
                    <Highlighter
                      searchValue={controlledSearchValue}
                      textToHighlight={text as string}
                      className="mr-1 w-100"
                    />
                  ) : (
                    <span className="mr-1 w-100">{text}</span>
                  )}
                  {extraText && !isSelected && <span className="extra ml-auto">{extraText}</span>}
                </div>
              </React.Fragment>
            );
          }
        )}
      </div>
      {children}
    </div>
  );
};

export const TooltipSelectCheckboxOption: FC<ITooltipOption> = ({
  id,
  text,
  onClick,
  testHook,
  isSelected,
  disabled,
  isVisible = true
}) => {
  const handleClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className={classNames('tooltip-select-checkbox-option', { disabled })}>
      <LabeledCheckbox
        label={text}
        id={id}
        checked={isSelected}
        onChange={handleClick}
        disabled={disabled}
        testHook={testHook}
      />
    </div>
  );
};

export const TooltipCheckboxSelect: FC<TooltipSelectProps> = ({ options, title }) => (
  <div className="tooltip-select p-3">
    {title && <div className="tooltip-select-title mb-2">{title}</div>}
    {options.map((option, index) => (
      <TooltipSelectCheckboxOption
        key={`option_${option.id || index}`}
        id={option.id}
        isSelected={option.isSelected}
        disabled={option.disabled}
        text={option.text}
        testHook={option.testHook}
        onClick={option.onClick}
        isVisible={option.isVisible}
      />
    ))}
  </div>
);

interface DropdownOption {
  text: string;
  id: string;
  onClick: (args: any) => any;
}

interface DropdownProps {
  children?: ReactNode;
  label?: string | JSX.Element;
  tooltipReference?: MutableRefObject<Element>;
  tooltipController?: TooltipController;
  disabled?: boolean;
  options: DropdownOption[];
}

export const TooltipDropdown: FC<DropdownProps> = ({
  label,
  tooltipController,
  tooltipReference,
  disabled,
  children,
  options
}) => (
  <Tooltip
    disabled={disabled}
    trigger={TooltipTrigger.CLICK}
    label={<span>{label}</span>}
    reference={tooltipReference}
    controller={tooltipController}
  >
    <TooltipSelect options={options}>{children}</TooltipSelect>
  </Tooltip>
);
