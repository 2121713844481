import { useState, useMemo } from 'react';

import { pickBy } from 'lodash/fp';
import { useLocalStorage } from 'react-use';

import { useStores } from 'mobx/hooks/useStores';

import FilterSetsFetcher from 'fetchers/FilterSetsFetcher';

import { sanitizeFiltersOrQuery } from 'utils/serverFiltersUtils';

import { FilterSetMetadata, FilterSet, FilterSetFilters } from 'views/Filters/filters.types';
import { useWqFilters } from 'views/Filters/useWqFilters';
import { WorkQueueRequestFilters } from 'views/WorkQueue/WorkQueue.types';
import {
  extractWqQueryFromFilters,
  extractFiltersFromQuery
} from 'views/WorkQueue/WorkQueue.utils';

export const ACTIVE_FILTER_SET_ID_LOCAL_STORAGE_KEY = 'activeFilterSetId';

export interface UseFilterSetsMethods {
  filterSetsMetadata: FilterSetMetadata[];
  activeFilterSetMetadata: FilterSetMetadata | null;
  initFilterSets: () => Promise<void>;
  chooseFilterSet: (id: number | null) => Promise<void>;
  loadFilterSets: () => Promise<void>;
  loadFilterSetById: (id: number) => Promise<FilterSet & { filters: FilterSetFilters }>;
  saveNewFilterSet: (filterSet: Omit<FilterSet, 'id'>) => Promise<number>;
  updateFilterSet: (filterSet: FilterSet) => Promise<void>;
  deleteFilterSet: (id: number) => Promise<void>;
  isDirty: boolean;
  isLoading: boolean;
}

export const useFilterSets = (): UseFilterSetsMethods => {
  const {
    cliniciansStore,
    constantsStore,
    locationsStore,
    providersStore,
    ticketTypesStore,
    settingsStore
  } = useStores();

  const { updateAllFilters, compareWithCurrentFilters, ...useWqFiltersRest } = useWqFilters();
  const wqFilters = useWqFiltersRest.filters;

  const [filterSetsMetadata, setFilterSetsMetadata] = useState<FilterSetMetadata[]>([]);

  const [activeFilterSetId, setActiveFilterSetId] = useLocalStorage<number | null>(
    ACTIVE_FILTER_SET_ID_LOCAL_STORAGE_KEY,
    null
  );

  const [activeFilterSet, setActiveFilterSet] = useState<FilterSetFilters | null>(null);

  let activeFilterSetMetadata: FilterSetMetadata | null = null;
  if (activeFilterSetId !== null) {
    activeFilterSetMetadata =
      filterSetsMetadata.find((set) => set.id === activeFilterSetId) ?? null;
  }

  const isNotNil = (value: any) => value !== null && value !== undefined;

  const [loadingCounter, setLoadingCounter] = useState(0);

  const loadWithCounting = async (fetchFunction: () => Promise<any>) => {
    setLoadingCounter((prev) => prev + 1);
    try {
      return await fetchFunction();
    } finally {
      setLoadingCounter((prev) => prev - 1);
    }
  };

  const removeExcludedFilters = ({
    toDate,
    fromDate,
    nameOrMrn,
    taskSearchTerm,
    ...filters
  }: Partial<WorkQueueRequestFilters>): Partial<WorkQueueRequestFilters> => {
    return filters;
  };

  const transformFiltersForServer = (
    filters: FilterSetFilters
  ): Partial<WorkQueueRequestFilters> => {
    return pickBy(
      isNotNil,
      removeExcludedFilters(extractWqQueryFromFilters(filters, ticketTypesStore))
    );
  };

  const loadFilterSets = async () => {
    const sets = await loadWithCounting(FilterSetsFetcher.fetchAllFilterSets);
    setFilterSetsMetadata(sets);
  };

  const loadFilterSetById = async (id: number) => {
    const { filters, ...updatedMetadata } = await loadWithCounting(() =>
      FilterSetsFetcher.fetchFilterSet(id)
    );
    const convertedFilters = await extractFiltersFromQuery(
      filters,
      cliniciansStore,
      constantsStore,
      locationsStore,
      providersStore,
      settingsStore
    );
    setFilterSetsMetadata((prev) => prev.map((set) => (set.id === id ? updatedMetadata : set)));
    return { filters: sanitizeFiltersOrQuery(convertedFilters), ...updatedMetadata };
  };

  /** Activates a filter set by its ID after loading its filters. */
  const chooseFilterSet = async function setActiveFilterSetById(id: number | null) {
    setActiveFilterSetId(id);
    let filterSet: (FilterSetMetadata & { filters: FilterSetFilters }) | null = null;
    try {
      if (id !== null) {
        filterSet = await loadFilterSetById(id);
      }
    } finally {
      if (!filterSet) {
        setActiveFilterSetId(null);
      }
      setActiveFilterSet(filterSet?.filters ?? null);
      updateAllFilters(
        {
          ...(filterSet?.filters ?? {}),
          toDate: wqFilters.toDate,
          fromDate: wqFilters.fromDate
        },
        isNotNil(filterSet)
      );
    }
  };

  /** Whether the active filter set has unsaved changes. */
  const isDirty = useMemo(() => {
    return !compareWithCurrentFilters(activeFilterSet ?? {});
  }, [activeFilterSet, compareWithCurrentFilters]);

  /** Loads filter sets and resumes the previous selected filter set after reading from local storage. */
  const initFilterSets = async () => {
    await loadFilterSets();
    if (activeFilterSetId !== null) {
      // null in case no filter set is set
      try {
        const filterSet = await loadFilterSetById(activeFilterSetId);
        setActiveFilterSet(filterSet.filters);
      } catch (error) {
        setActiveFilterSetId(null);
        setActiveFilterSet(null);
        throw error;
      }
    }
  };

  /** Saves a new filter set, and sets it as the active filter set. */
  const saveNewFilterSet = async ({ name, filters, isShared }: Omit<FilterSet, 'id'>) => {
    const createdFilterSetId = await loadWithCounting(() =>
      FilterSetsFetcher.createFilterSet({
        name,
        filters: transformFiltersForServer(filters),
        isShared
      })
    );
    await loadFilterSets();
    return createdFilterSetId;
  };

  /** Updates an existing filter set. */
  const updateFilterSet = async ({ id, name, filters, isShared }: FilterSet) => {
    await loadWithCounting(() =>
      FilterSetsFetcher.updateFilterSet({
        id,
        name,
        filters: transformFiltersForServer(filters),
        isShared
      })
    );
    await loadFilterSets();
  };

  const deleteFilterSet = async (id: number) => {
    await FilterSetsFetcher.deleteFilterSet(id);
    if (activeFilterSetId === id) {
      setActiveFilterSetId(null);
      setActiveFilterSet(null);
    }
    await loadFilterSets();
  };

  return {
    filterSetsMetadata,
    activeFilterSetMetadata,
    initFilterSets,
    chooseFilterSet,
    loadFilterSets,
    loadFilterSetById,
    saveNewFilterSet,
    updateFilterSet,
    deleteFilterSet,
    isDirty,
    isLoading: loadingCounter > 0
  };
};
