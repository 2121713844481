import { FC, ReactNode } from 'react';

import classNames from 'classnames';

import { useInView } from 'react-intersection-observer';

import { Testable } from 'utils/TypeUtils';

import useTicketOverviewContext from 'components/Ticket/TicketsContainers/useTicketOverviewContext';

import './Card.scss';

interface Props extends Testable {
  children?: ReactNode;
  dashedBorder?: boolean;
  highlight?: boolean;
}
const Card: FC<Props> = ({ children, dashedBorder = false, highlight = false, testHook }) => {
  const classes = classNames('base-card', { 'dashed-border': dashedBorder, highlight });

  return (
    <div className={classes} data-test-hook={testHook}>
      {children}
    </div>
  );
};

interface CardRowProps extends Testable {
  children: ReactNode;
  noMinHeight?: boolean;
}
export const CardRow: FC<CardRowProps> = ({ children, noMinHeight, testHook }) => {
  const ticketOverviewContext = useTicketOverviewContext();
  const { ref, inView } = useInView({
    /* Optional options */
  });
  const classes = classNames('card-row', { 'no-min-height': noMinHeight });

  if (!ticketOverviewContext?.useIntersectionObserver) {
    return (
      <div className={classes} data-test-hook={testHook || 'card-row'}>
        {children}
      </div>
    );
  }

  return (
    <div className={classes} ref={ref} data-test-hook={testHook || 'card-row'}>
      {inView && children}
    </div>
  );
};

export default Card;
