// @ts-strict-ignore

import { FunctionComponent, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

import { observer } from 'mobx-react';

import { useHistory, useLocation } from 'react-router-dom';

import useNetworkLoading from 'mobx/hooks/useNetworkLoading';
import { useStores } from 'mobx/hooks/useStores';

import { ExtendedTicket } from 'utils/TicketClusteringUtils';
import { Testable } from 'utils/TypeUtils';
import { removeUrlParam } from 'utils/urlUtils';

import { API_LABELS } from 'constants/apiUrls';

import Patient from 'models/Patient';
import { TicketStatus } from 'models/Ticket';

import { useLocalPagination } from 'hooks/useLocalPagination';

import { HIGHLIGHT_TICKET_QUERY_PARAM } from 'views/Patient/PatientMain/PatientMainView.constants';
import { TicketSectionId } from 'views/WorkQueue/WorkQueue.types';

import FixedLoader from 'components/Loaders/FixedLoader';
import { Paginator } from 'components/Paginator';
import TicketsList from 'components/Ticket/TicketsContainers/TicketsList';

const RESOLVED_TICKETS_PAGE_SIZE = 10;

interface Props extends Testable {
  patient: Patient;
  emptyState?: ReactElement;
}

const ResolvedTickets: FunctionComponent<Props> = ({ patient, emptyState, testHook }) => {
  const { ticketsStore } = useStores();
  const { search } = useLocation();
  const history = useHistory();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const highlightId = Number(params.get(HIGHLIGHT_TICKET_QUERY_PARAM));
  const [ticketRefs, setTicketRefs] = useState<any>({});

  useEffect(() => {
    ticketsStore.searchTickets({
      patientId: patient.id,
      ticketStatuses: [TicketStatus.RESOLVED]
    });
  }, [patient.id, ticketsStore]);

  const isLoading = useNetworkLoading(API_LABELS.TICKETS_BY_PATIENT(patient?.id));

  const sortedResolvedTicketsWithReports =
    ticketsStore.getPatientSortedResolvedTicketsWithReports(patient);

  const paginationProps = useLocalPagination<ExtendedTicket>(
    sortedResolvedTicketsWithReports,
    RESOLVED_TICKETS_PAGE_SIZE
  );

  useEffect(
    function goToHighlightTicketPage() {
      if (highlightId && sortedResolvedTicketsWithReports) {
        const ticketIndex = sortedResolvedTicketsWithReports.findIndex(
          (extendedTicket) => extendedTicket.ticket.id === highlightId
        );
        if (ticketIndex !== -1) {
          const page = Math.floor(ticketIndex / RESOLVED_TICKETS_PAGE_SIZE);
          if (page !== paginationProps.pageNumber) {
            paginationProps.setPageNumber(() => page);
          }
        }
      }
    },
    [highlightId, sortedResolvedTicketsWithReports, paginationProps]
  );

  const ticketRefsCallback = useCallback((node: HTMLElement, ticketId: number) => {
    setTicketRefs((prev: Record<number, HTMLElement>) => ({ ...prev, [ticketId]: node }));
  }, []);

  const handlePageChanged = useCallback(
    (currentPage: number) => {
      removeUrlParam(history, search, HIGHLIGHT_TICKET_QUERY_PARAM);
      paginationProps.setPageNumber(() => currentPage - 1);
    },
    [history, search, paginationProps]
  );

  const pagination = useMemo(() => {
    const pageNumber: number = paginationProps.pageNumber;

    return (
      paginationProps.numberOfPages > 1 && (
        <Paginator
          currentPage={pageNumber + 1}
          totalPages={paginationProps.numberOfPages}
          onPageChange={handlePageChanged}
        />
      )
    );
  }, [handlePageChanged, paginationProps]);

  const ticketToScroll = ticketRefs[highlightId];

  useEffect(
    function scrollToHighlightTicket() {
      if (ticketToScroll) {
        document.getElementById('app')!.scrollBy({
          top: ticketToScroll.getBoundingClientRect().top - window.innerHeight / 3,
          behavior: 'smooth'
        });
      }
    },
    [ticketToScroll, patient.id]
  );

  const content = useMemo(() => {
    if (!paginationProps.currentPage) {
      return emptyState || null;
    }
    return (
      <>
        {pagination}
        {isLoading && <FixedLoader />}
        <div>
          <TicketsList
            tickets={paginationProps.currentPage}
            ticketRefsCallback={ticketRefsCallback}
            sectionId={TicketSectionId.ResolvedItems}
          />
        </div>
        {pagination}
      </>
    );
  }, [emptyState, isLoading, pagination, paginationProps.currentPage, ticketRefsCallback]);

  return <div data-test-hook={testHook}>{content}</div>;
};

export default observer(ResolvedTickets);
