// @ts-strict-ignore
import { action, makeObservable, observable, runInAction } from 'mobx';

import {
  PatientCommunicationFetcher,
  ReportRequestDetails
} from 'fetchers/PatientCommunicationFetcher';
import PatientsFetcher, {
  SearchedPatient,
  getPatientRequestBaseProperties,
  CreateAndInvitePatientBody,
  UpdateAndInvitePatientBody
} from 'fetchers/PatientsFetcher';

import { handleErrorSilently } from 'services/errorHandlingService';

import {
  getShortRemindedAllMessage,
  showRequestReportSummaryToast,
  showToast
} from 'utils/UserMessageUtils';

import Patient, { IPatientContact, IPatientUpdateableFields } from 'models/Patient';
import { ProtocolType } from 'models/ScheduledProtocol';

import Ticket from 'models/Ticket';

import { RECENT_PATIENTS_SEARCH_LIMIT } from 'components/UIkit/atoms/AdvancedSearchBar/AdvancedSearchBar.constants';

import { RootStore } from './rootStore';

export class PatientPageStore {
  @observable
  patient: Patient | null = null;

  @observable
  searchedPatients: Patient[] = null;

  @observable
  patientTickets: Ticket[] = null;

  @observable
  recentSearchedPatients: SearchedPatient[] = [];

  @observable
  isLoading: boolean = false;

  rootStore: RootStore = null;

  refreshSinglePatientInterval: NodeJS.Timeout;

  @observable
  lastSinglePatientRetrievedTimestamp: number;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  fetchPatient(patientId: number) {
    return PatientsFetcher.loadPatient(patientId);
  }

  loadSinglePatientFromServer = async (patientId: number, updateTimestamp = true) => {
    const timestamp = Date.now();
    this.setIsLoading(true);

    try {
      const { patient, tickets } = await this.fetchPatient(patientId);
      runInAction(() => {
        if (updateTimestamp) {
          this.lastSinglePatientRetrievedTimestamp = timestamp;
        }

        this.setPatient(patient, tickets);
      });

      return patient;
    } finally {
      this.setIsLoading(false);
    }
  };

  @action
  loadSinglePatientDataPeriodically(patientId: number) {
    if (!this.refreshSinglePatientInterval) {
      const { singlePatientRetrievalInterval } =
        this.rootStore.stores.settingsStore.institutionSettings;
      this.refreshSinglePatientInterval = setInterval(() => {
        this.loadSinglePatientFromServer(patientId).catch(handleErrorSilently);
      }, singlePatientRetrievalInterval);
    }
    return this.loadSinglePatientFromServer(patientId);
  }

  loadPatientAndTickets(patientId: number, clearTickets?: boolean) {
    return Promise.all([
      this.rootStore.stores.ticketsStore.fetchTicketsForPatient(patientId, clearTickets),
      this.loadSinglePatientFromServer(patientId)
    ]);
  }

  @action
  stopRefreshSinglePatient() {
    clearInterval(this.refreshSinglePatientInterval);
    this.refreshSinglePatientInterval = null;
  }

  @action
  setPatient(patient: Patient, tickets?: Ticket[]) {
    this.patient = patient;
    this.patientTickets = tickets;
  }

  @action
  clearData() {
    // We currently hold references to a patient outside PP context
    // This makes references to patient's calls and reports stay in memory even when in WQ for example
    // Should be remove once we have better separation
    if (this.patient) {
      this.patient.questionnairesAnswers = null;
      this.patient.callsMap.clear();
    }

    this.patient = null;
  }

  @action
  resetStore() {
    this.clearData();
    this.stopRefreshSinglePatient();
  }

  @action
  clearSearchedPatients() {
    this.searchedPatients = null;
  }

  async createAndInvitePatient(
    patient: Patient,
    activateProtocol: boolean,
    inviteTimeInSeconds: number
  ) {
    try {
      this.setIsLoading(true);
      const baseProperties = getPatientRequestBaseProperties(patient);

      const createAndInvitePatientRequestBody: CreateAndInvitePatientBody = {
        ...baseProperties,
        activateProtocol,
        status: patient.status,
        sourceId: patient.sourceId,
        protocol: patient.mainScheduledProtocol,
        emrPatientId: patient.emrPatientId,
        inviteTimeInSeconds
      };

      await PatientsFetcher.invitePatient(createAndInvitePatientRequestBody);
    } finally {
      this.setIsLoading(false);
    }
  }

  async updateAndInvitePatient(
    patientId: number,
    updatableFields: IPatientUpdateableFields,
    activateProtocol: boolean
  ) {
    try {
      this.setIsLoading(true);

      const baseProperties = getPatientRequestBaseProperties(updatableFields);
      const updateAndInvitePatientRequestBody: UpdateAndInvitePatientBody = {
        ...baseProperties,
        activateProtocol,
        patientId,
        protocol: updatableFields.protocol
      };

      await PatientsFetcher.invitePatient(updateAndInvitePatientRequestBody);
    } finally {
      this.setIsLoading(false);
    }
  }

  @action
  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  updatePatient(
    patientId: number,
    updateFields: IPatientUpdateableFields,
    activateProtocol: boolean = null
  ) {
    this.setIsLoading(true);

    return PatientsFetcher.updatePatient(patientId, updateFields, activateProtocol)
      .then(() => {
        if (this.patient) {
          this.patient.setUpdate(updateFields);
        }
      })
      .finally(() => {
        this.setIsLoading(false);
      });
  }

  @action
  updateRecentSearchedPatients(
    patientId: number,
    firstName: string,
    lastName: string,
    dateOfBirth: string
  ) {
    const recentPatient = this.recentSearchedPatients.find(
      (recentPatient) => recentPatient.patientId === patientId
    );

    if (recentPatient) {
      recentPatient.firstName = firstName;
      recentPatient.lastName = lastName;
      recentPatient.dob = dateOfBirth;
    }
  }

  @action
  clearRecentSearchedPatients() {
    this.recentSearchedPatients = [];
  }

  @action
  addToRecentSearchedPatients(patient: SearchedPatient) {
    const isPatientExist = this.recentSearchedPatients.find(
      (recentPatient) => recentPatient.patientId === patient.patientId
    );

    if (isPatientExist) {
      //if the user click on a recent result, I want to reorder the array and move the selected result to be the first
      this.recentSearchedPatients = this.recentSearchedPatients.filter(
        (recentPatient) => recentPatient.patientId !== patient.patientId
      );
    }

    this.recentSearchedPatients.unshift(patient);
    this.recentSearchedPatients = this.recentSearchedPatients.slice(
      0,
      RECENT_PATIENTS_SEARCH_LIMIT
    );
  }

  @action
  getPatientsToInvite(query: string, includeSelf: boolean = false) {
    return PatientsFetcher.searchGlobalPatientsAndParse({ searchTerm: query, includeSelf }).then(
      ({ patients }) => {
        // In order to avoid re rendering on each search we only set the list if it has changed
        if (!this.hasInvitePatientsListChanged(patients)) {
          return;
        }
        runInAction(() => {
          this.searchedPatients = patients;
        });
      }
    );
  }

  async sendReportRequest(
    patientData: Patient,
    getRequestDetails: (patientData?: Patient) => ReportRequestDetails
  ) {
    const patientDetails = patientData;

    const reportRequestDetails = getRequestDetails(patientData);

    if (!reportRequestDetails) {
      showToast({ message: getShortRemindedAllMessage(0) });
    }
    let result;
    result = await PatientCommunicationFetcher.sendReportRequest(reportRequestDetails);

    if (reportRequestDetails.reportType === ProtocolType.mobile) {
      const isBlocked = result.blockedIds.includes(patientDetails.id);
      showRequestReportSummaryToast(result.successIds.length, isBlocked ? [patientData] : []);
    }
  }

  async snooze(patientId: number) {
    await PatientCommunicationFetcher.snoozePatient(patientId);
  }

  private hasInvitePatientsListChanged(patients: Patient[]) {
    if (this.searchedPatients && this.searchedPatients.length === patients.length) {
      for (let i = 0; i < patients.length; i++) {
        if (patients[i].id !== this.searchedPatients[i].id) {
          return true;
        }
      }
      return false;
    }
    return true;
  }

  async createContact(patientId: number, contactInfo: IPatientContact) {
    const newContact = await PatientsFetcher.addContact(patientId, contactInfo);
    if (!this.patient) {
      return;
    }
    this.patient.addContact(newContact);
    return newContact;
  }

  async updateContact(patientId: number, contactId: number, contactInfo: IPatientContact) {
    const updatedContact = await PatientsFetcher.updateContact(patientId, contactId, contactInfo);
    if (!this.patient) {
      return;
    }
    this.patient.updateContact(updatedContact.id, updatedContact);
    return updatedContact;
  }

  async deleteContact(patientId: number, contact: IPatientContact) {
    await PatientsFetcher.deleteContact(patientId, contact.id);
    if (!this.patient) {
      return;
    }
    this.patient.deleteContact(contact.id);
  }

  async createPatientFromEMR(emrPatientId: string): Promise<number> {
    return await PatientsFetcher.createPatientFromEmr(emrPatientId);
  }

  updateStartOfCycle(questionnaireId: number, date: Date): Promise<void> {
    return PatientsFetcher.updateStartOfCycle(questionnaireId, date);
  }
}

export default PatientPageStore;
