import { FC, ReactNode } from 'react';

import { Typography } from '@mui/material';
import { useTrackSectionHeaderViewEvent } from 'analytics/events/section-header-view';
import classNames from 'classnames';

import { Collapse } from 'reactstrap';
import { sharedTestSelectors } from 'tests/shared/shared.selectors';

import { Testable } from 'utils/TypeUtils';

import 'views/Widgets/CollapsibleSection.scss';

import OpenCloseArrow from 'views/Widgets/OpenCloseArrow';

import {
  WqSectionTrigger,
  WqSectionTriggerProps
} from 'views/WorkQueue/WorkQueueOpenItemsView/WqSectionTrigger';

import { WqTasksSectionTrigger } from 'views/WorkQueue/WorkQueueOpenItemsView/WqTasksSectionTrigger';

import { useSectionExpand } from 'components/Ticket/TicketsContainers/useTicketOverviewContext';

interface Props extends WqSectionTriggerProps, Testable {
  children: ReactNode;
  onTrigger: (isOpen: boolean) => void;
  totalPatients?: number;
}

const WqCollapsibleSection: FC<Props> = ({
  sectionName,
  totalItems,
  totalPatients,
  children,
  onTrigger,
  testHook
}) => {
  const [isExpanded, setIsExpanded] = useSectionExpand(sectionName);

  const classes = classNames('collapsible-section', {
    'is-collapsed': !isExpanded
  });

  const { ref } = useTrackSectionHeaderViewEvent(sectionName);

  const toggle = () => {
    onTrigger(isExpanded);
    setIsExpanded();
  };

  return (
    <div className={classes} data-test-hook={testHook}>
      <div className="collapsible-section-header" ref={ref} id={sectionName}>
        <div
          className="collapsible-section-trigger"
          onClick={toggle}
          data-test-hook={sharedTestSelectors.ticketSection.trigger(testHook!)}
        >
          <OpenCloseArrow isOpen={isExpanded} />
          <Typography variant="h3" component="span">
            {totalPatients ? (
              <WqTasksSectionTrigger totalItems={totalItems} totalPatients={totalPatients} />
            ) : (
              <WqSectionTrigger sectionName={sectionName} totalItems={totalItems} />
            )}
          </Typography>
        </div>
      </div>

      <div>
        <Collapse
          data-test-hook={sharedTestSelectors.ticketSection.collapse(testHook!)}
          isOpen={isExpanded}
        >
          {children}
        </Collapse>
      </div>
    </div>
  );
};

export default WqCollapsibleSection;
