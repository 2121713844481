export const compactArray = (array: any[]) => array.filter((obj) => obj);

export const extractIdsFromItems = (items: any[]): Array<number> => {
  return items.map((item: any) => item.id);
};

export const createMapBy = <T>(array: T[], getKey: (item: T) => number | string) => {
  const result = new Map<number | string, T>();
  array.forEach((item: any) => {
    result.set(getKey(item), item);
  });
  return result;
};
