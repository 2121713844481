// @ts-strict-ignore
import { difference } from 'lodash/fp';
import { action, computed, makeObservable, observable } from 'mobx';

import { RootStore } from 'mobx/stores';

import DepartmentsFetcher from 'fetchers/DepartmentFetcher';

import Department from 'models/Department';

import { DataMap } from './DataMap';

export default class DepartmentStore {
  @observable
  rootDepartmentId: number = null;

  @observable
  departments = new DataMap<Department>();

  rootStore: RootStore;

  @computed
  get root(): Department {
    return this.departments.get(this.rootDepartmentId);
  }

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action
  setDepartments(departments: Department[]) {
    this.departments.setItems(departments);
    const rootDepartment = departments.find((department: Department) => !department.parentId);
    if (rootDepartment) {
      this.rootDepartmentId = rootDepartment.id;
    } else {
      throw new Error("Can't find root department");
    }
  }
  async fetchDepartments() {
    const departments = await DepartmentsFetcher.fetchDepartments();
    this.setDepartments(departments);
  }

  @computed
  get lowestLevelDepartments() {
    const allParentIds = this.getInstitutionDepartmentsAsArray()
      .map((department) => department.parentId)
      .filter((parentId) => parentId);

    const leafNodeIds = difference(this.departments.keysArray, allParentIds);

    return this.departments.getItemByKeys(leafNodeIds);
  }

  @computed
  get departmentsForSelect(): Array<{ value: number; label: string }> {
    return this.root
      ? this.lowestLevelDepartments.map((department) => ({
          value: department.id,
          label: department.name
        }))
      : [];
  }

  getInstitutionDepartmentsAsArray = () => {
    return this.departments.items;
  };
}
