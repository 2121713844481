import { transformErrorUiProps, ErrorName, HttpError } from 'errors';

import HttpService from 'services/HttpService';

import { API_URLS } from 'constants/apiUrls';

import { HttpStatusCode } from 'constants/httpStatusCode.const';

import { FilterSetMetadata, FilterSetServerData } from 'views/Filters/filters.types';

const httpService = new HttpService('filter-sets', true);

const STANDARD_ERROR_DESCRIPTION = 'An unknown error occurred. Please try again in a moment. ';

const transformFilterSetError = (
  title: string,
  description: string = STANDARD_ERROR_DESCRIPTION
) => {
  return (error: HttpError) => {
    error.name = ErrorName.FilterSetActionFailed;
    return transformErrorUiProps(title, description)(error);
  };
};

class FilterSetsFetcher {
  static async fetchAllFilterSets() {
    return await httpService.get<FilterSetMetadata[]>({
      url: API_URLS.FILTER_SETS,
      transformError: transformFilterSetError('Failed to Load Filter Sets')
    });
  }

  static async fetchFilterSet(id: number) {
    return await httpService.get<FilterSetServerData>({
      url: API_URLS.GET_FILTER_SET_DATA(id),
      transformError: (error: HttpError) => {
        if (error.httpFailure.statusCode === HttpStatusCode.NOT_FOUND) {
          return transformFilterSetError(
            'Failed to Load Filter Set',
            'The Filter Set may have been un-shared or deleted.'
          )(error);
        }
        return transformFilterSetError('Failed to Load Filter Set')(error);
      }
    });
  }

  static async createFilterSet({ name, filters, isShared }: Omit<FilterSetServerData, 'id'>) {
    return await httpService.post<number>({
      url: API_URLS.FILTER_SETS,
      data: { name, isShared, filters },
      transformError: transformFilterSetError('Failed to Save Filter Set')
    });
  }

  static async updateFilterSet({ id, name, filters, isShared }: FilterSetServerData) {
    return await httpService.put<void>({
      url: API_URLS.UPDATE_FILTER_SET(id),
      data: { name, isShared, filters },
      transformError: transformFilterSetError('Failed to Update Filter Set')
    });
  }

  static async deleteFilterSet(id: number): Promise<void> {
    return await httpService.delete({
      url: API_URLS.DELETE_FILTER_SET(id),
      transformError: transformFilterSetError('Cannot Delete Filter Set')
    });
  }
}

export default FilterSetsFetcher;
