// @ts-strict-ignore
import { FC, useCallback, useMemo, useRef, useState } from 'react';

import { AnalyticEventAction } from 'analytics';
import { trackSendSmsAnalyticsEvent } from 'analytics/events/send-sms';

import i18n from 'i18n';
import { observer } from 'mobx-react';

import { useUpdateEffect } from 'react-use';

import { useStores } from 'mobx/hooks/useStores';

import { PatientCommunicationFetcher } from 'fetchers/PatientCommunicationFetcher';

import { showToast } from 'utils/UserMessageUtils';

import { API_HOST, ENV } from 'constants/config';

import Patient, { IPatientUpdateableFields } from 'models/Patient';

import ScheduledProtocol, {
  ProtocolAutomationMode,
  ProtocolName,
  ProtocolOperationMode,
  ProtocolType
} from 'models/ScheduledProtocol';

import { useToggle } from 'hooks/useToggle';

import SendSmsPersonalRequest from 'views/Modals/SendSmsPersonalRequest';
import SmsBlockedPopup from 'views/Modals/SmsBlockedPopup';

import { HybridProtocolOption } from 'views/Widgets/HybridProtocolDropdown';

import PatientActionsSelect from './PatientActionsSelect';

const getDefaultSymptomsSmsMessage = (
  recipientFirstName: string,
  clinicianName: string,
  instituteName: string = 'the clinic',
  locale: string
) => {
  return i18n.t('defaultSymptomsReminderSms', {
    name: recipientFirstName,
    doctor: clinicianName,
    institution: instituteName,
    phone: ENV === 'production' ? '(833) 288-7683' : '(213) 270-1782',
    url: `${API_HOST}/app`,
    lng: locale
  });
};

const getDefaultCovidRequestText = (firstName: string, doctorName: string, locale: string) => {
  return i18n.t('defaultCovidReminderSMS', { name: firstName, doctor: doctorName, lng: locale });
};

function isCovid(patient: Patient) {
  const { mainScheduledProtocol } = patient;
  return mainScheduledProtocol?.name === ProtocolName.covid;
}

function useDefaultSmsMessage(patient: Patient) {
  const { userStore, departmentsStore } = useStores();
  return useMemo(
    () =>
      isCovid(patient)
        ? getDefaultCovidRequestText(
            patient.firstName,
            userStore.currentDoctor.firstName,
            patient.locale
          )
        : getDefaultSymptomsSmsMessage(
            patient.firstName,
            userStore.currentDoctor.firstName,
            departmentsStore!.root.name,
            patient.locale
          ),
    [patient, userStore, departmentsStore]
  );
}

function useSmsMessage(patient: Patient) {
  const ref = useRef<Patient>(patient);
  const defaultRequestSmsMessage = useDefaultSmsMessage(patient);
  const [value, setValue] = useState(defaultRequestSmsMessage);

  const reset = useCallback(() => {
    setValue(defaultRequestSmsMessage);
  }, [defaultRequestSmsMessage]);

  useUpdateEffect(
    function onPatientsChange() {
      if (ref.current !== patient) {
        // on patient change - reset default message
        reset();
        ref.current = patient;
      }
    },
    [patient]
  );
  return { value, setValue, reset };
}

interface Props {
  patient: Patient;
}

export const PatientDetailsCardActions: FC<Props> = observer(({ patient }) => {
  const reminderSmsMessage = useSmsMessage(patient);
  const [patientSmsMessage, setPatientSmsMessage] = useState('');
  const [isSendReminderSmsInProgress, setIsSendReminderSmsInProgress] = useState(false);
  const [isPatientSmsInProgress, setIsPatientSmsInProgress] = useState(false);
  const requestReportSmsModal = useToggle(false);
  const sendPatientSmsModal = useToggle(false);

  const smsBlockedPopup = useToggle(false);
  const { patientPageStore, userStore } = useStores();

  const getRequestDetailsForPhoneRequest = () => ({
    patientId: patient.id,
    message: getDefaultCovidRequestText(
      patient.firstName,
      userStore.currentDoctor.fullName,
      patient.locale
    ),
    reportType: ProtocolType.phone,
    reportName: patient.mainScheduledProtocol
      ? patient.mainScheduledProtocol.name
      : patient.lastReportNameRequested
  });

  const handlePhoneRequestClicked = async () => {
    await patientPageStore!.sendReportRequest(patient, getRequestDetailsForPhoneRequest);
    patientPageStore.loadSinglePatientFromServer(patient.id);
    showToast({ message: 'Dialing Patient Now…' });
  };

  const createHybridProtocol = (duration: HybridProtocolOption) => {
    const scheduledProtocol = new ScheduledProtocol();
    scheduledProtocol.operationMode = ProtocolOperationMode.Automatic;
    scheduledProtocol.automationMode =
      duration === HybridProtocolOption.Weekly
        ? ProtocolAutomationMode.Weekly
        : ProtocolAutomationMode.Monthly;
    scheduledProtocol.name = ProtocolName.symptom;
    scheduledProtocol.type = ProtocolType.mobile;
    const today = new Date();
    scheduledProtocol.info = {
      endType: 3,
      startingDate: today
    };
    return scheduledProtocol;
  };

  const handleProtocolSelected = async (duration: HybridProtocolOption) => {
    const scheduledProtocol = createHybridProtocol(duration);
    await saveNewProtocol(scheduledProtocol);
  };

  const saveNewProtocol = async (scheduledProtocol: ScheduledProtocol) => {
    // TODO: remove this when we have a better way to update the patient (duplicated in all other usages)
    // seems like the only fields really required here are locale and protocol
    scheduledProtocol.type = ProtocolType.mobile;
    const updateFields: IPatientUpdateableFields = {
      firstName: patient.firstName,
      lastName: patient.lastName,
      phone: patient.phone,
      phoneType: patient.phoneType,
      dateOfBirth: patient.dateOfBirth,
      remoteMonitoringConsent: patient.remoteMonitoringConsent,
      copayConsentGiven: patient.copayConsentGiven,
      patientReadTerms: patient.patientReadTerms,
      sex: patient.sex,
      mrn: patient.mrn,
      locale: patient.locale,
      protocol: scheduledProtocol,
      optOut: patient.optOut,
      location: patient.location,
      enrollmentStatus: patient.enrollmentStatus,
      financialAssistance: patient.financialAssistance,
      providerId: patient.providerId,
      tags: patient.tags
    };

    await patientPageStore.updatePatient(patient.id, updateFields);
    showToast({ message: 'Report Invitation Sent' });
  };

  const getRequestDetailsForPersonalRequest = () => {
    const scheduledProtocol = patient.mainScheduledProtocol;
    let reportType;
    if (scheduledProtocol && scheduledProtocol.type === ProtocolType.phone) {
      reportType = ProtocolType.mobile;
    } else {
      reportType = scheduledProtocol ? scheduledProtocol.type : ProtocolType.mobile;
    }

    return {
      patientId: patient.id,
      message: reminderSmsMessage.value,
      reportType,
      reportName: scheduledProtocol ? scheduledProtocol.name : patient.lastReportNameRequested
    };
  };

  const handleSendPersonalRequest = async () => {
    setIsSendReminderSmsInProgress(true);
    try {
      await patientPageStore!.sendReportRequest(patient, getRequestDetailsForPersonalRequest);
      patientPageStore.loadSinglePatientFromServer(patient.id);
    } finally {
      setIsSendReminderSmsInProgress(false);
      requestReportSmsModal.setIsOpen(false);
      reminderSmsMessage.reset();
    }
  };

  const handleSendPatientSms = async () => {
    trackSendSmsAnalyticsEvent({
      action: AnalyticEventAction.Send
    });

    setIsPatientSmsInProgress(true);

    try {
      await PatientCommunicationFetcher.sendSmsToPatient(patient.id, {
        message: patientSmsMessage
      });
      showToast({
        message: `Message Sent to ${patient.fullName}`
      });
    } finally {
      setIsPatientSmsInProgress(false);
      sendPatientSmsModal.setIsOpen(false);
      setPatientSmsMessage('');
    }
  };

  const onSmsCancelClicked = () => {
    trackSendSmsAnalyticsEvent({
      action: AnalyticEventAction.Cancel
    });

    sendPatientSmsModal.toggle();
  };

  const handleSendPatientSmsActionClicked = (shouldOpenSmsBlockedPopup: boolean) => {
    if (shouldOpenSmsBlockedPopup) {
      trackSendSmsAnalyticsEvent({
        action: AnalyticEventAction.Send,
        value: 'blocked'
      });
      smsBlockedPopup.toggle();
    } else {
      sendPatientSmsModal.toggle();
    }
  };

  return (
    <div className="buttons-container" data-test-hook="patient-details-actions">
      <PatientActionsSelect
        patient={patient}
        onRequestReport={(shouldOpenSmsBlockedPopup: boolean) =>
          shouldOpenSmsBlockedPopup ? smsBlockedPopup.toggle() : requestReportSmsModal.toggle()
        }
        onPhoneClicked={handlePhoneRequestClicked}
        onSendPatientSmsClicked={handleSendPatientSmsActionClicked}
        onProtocolSelected={handleProtocolSelected}
      />
      <SendSmsPersonalRequest
        onSendClicked={handleSendPersonalRequest}
        onCancelClicked={requestReportSmsModal.toggle}
        onMessageChanged={reminderSmsMessage.setValue}
        message={reminderSmsMessage.value}
        isOpen={requestReportSmsModal.isOpen}
        isSending={isSendReminderSmsInProgress}
        title={`SMS to ${patient?.fullName}`}
      />
      <SendSmsPersonalRequest
        onSendClicked={handleSendPatientSms}
        onCancelClicked={onSmsCancelClicked}
        onMessageChanged={setPatientSmsMessage}
        message={patientSmsMessage}
        isOpen={sendPatientSmsModal.isOpen}
        isSending={isPatientSmsInProgress}
        title={`SMS to ${patient?.fullName}`}
        helperText="This is a one-way SMS. Patient will not be able to reply to this message."
      />
      <SmsBlockedPopup isOpen={smsBlockedPopup.isOpen} onCancelClicked={smsBlockedPopup.toggle} />
    </div>
  );
});
