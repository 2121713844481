import { workQueueTestSelectors } from 'tests/models/pages/work-queue/work-queue-page.selectors';

import { getDefaultDatesRequestParams } from 'utils/serverFiltersUtils';

import {
  WorkQueueOpenItemsSectionName,
  WorkQueueRequestFilters,
  WorkQueueSectionFilters,
  TicketSectionId,
  WorkQueueSections,
  WorkQueueSectionStatusData,
  WorkQueueTab,
  WorkQueueTabName
} from 'views/WorkQueue/WorkQueue.types';

import { Section } from 'components/Ticket/TicketsContainers/TicketsContainers.constants';

export const defaultWqSectionDataMap: WorkQueueSections = {
  [TicketSectionId.AssignedToMe]: { totalItems: 0, totalPages: 0, items: [], pageNumber: 0 },
  [TicketSectionId.UrgentPatientReports]: {
    totalItems: 0,
    totalPages: 0,
    items: [],
    pageNumber: 0
  },
  [TicketSectionId.TicketsAndCallbackRequests]: {
    totalItems: 0,
    totalPages: 0,
    items: [],
    pageNumber: 0
  },
  [TicketSectionId.OtherPatientReports]: {
    totalItems: 0,
    totalPages: 0,
    items: [],
    pageNumber: 0
  },
  [TicketSectionId.TasksDue]: {
    totalItems: 0,
    totalPages: 0,
    items: [],
    totalPatients: 0,
    pageNumber: 0
  },
  [TicketSectionId.OverdueReports]: { totalItems: 0, totalPages: 0, items: [], pageNumber: 0 }
};

export const defaultWqSectionCurrentPageMap: Record<WorkQueueOpenItemsSectionName, number> = {
  [TicketSectionId.AssignedToMe]: 0,
  [TicketSectionId.UrgentPatientReports]: 0,
  [TicketSectionId.OtherPatientReports]: 0,
  [TicketSectionId.TasksDue]: 0,
  [TicketSectionId.TicketsAndCallbackRequests]: 0,
  [TicketSectionId.OverdueReports]: 0
};

export const defaultWqSectionStatusData: WorkQueueSectionStatusData = {
  newItemsCount: 0,
  maxUrgency: null
};

export const defaultWqSectionStatusDataMap: Record<
  WorkQueueOpenItemsSectionName,
  WorkQueueSectionStatusData
> = {
  [TicketSectionId.AssignedToMe]: defaultWqSectionStatusData,
  [TicketSectionId.UrgentPatientReports]: defaultWqSectionStatusData,
  [TicketSectionId.OtherPatientReports]: defaultWqSectionStatusData,
  [TicketSectionId.TasksDue]: defaultWqSectionStatusData,
  [TicketSectionId.TicketsAndCallbackRequests]: defaultWqSectionStatusData,
  [TicketSectionId.OverdueReports]: defaultWqSectionStatusData
};

export const defaultWqFiltersQuery: WorkQueueRequestFilters = {
  fromDate: getDefaultDatesRequestParams().fromDate
};

export const WQ_EXPAND_KEY = 'WQ_EXPAND_STATE';

export const allWqOpenItemsSectionNames: WorkQueueOpenItemsSectionName[] = [
  TicketSectionId.AssignedToMe,
  TicketSectionId.UrgentPatientReports,
  TicketSectionId.TicketsAndCallbackRequests,
  TicketSectionId.OtherPatientReports,
  TicketSectionId.TasksDue,
  TicketSectionId.OverdueReports
];

export const defaultWqOpenItemsSectionFiltersMap: Record<
  WorkQueueOpenItemsSectionName,
  WorkQueueSectionFilters
> = {
  [TicketSectionId.AssignedToMe]: {
    lastRetrievedTimestamp: null
  },
  [TicketSectionId.UrgentPatientReports]: {
    lastRetrievedTimestamp: null
  },
  [TicketSectionId.OtherPatientReports]: {
    lastRetrievedTimestamp: null
  },
  [TicketSectionId.TasksDue]: {
    lastRetrievedTimestamp: null
  },
  [TicketSectionId.TicketsAndCallbackRequests]: {
    lastRetrievedTimestamp: null
  },
  [TicketSectionId.OverdueReports]: {
    lastRetrievedTimestamp: null
  }
};

export const sectionToNameMap: Record<WorkQueueOpenItemsSectionName, Section> = {
  [TicketSectionId.AssignedToMe]: Section.Assigned,
  [TicketSectionId.UrgentPatientReports]: Section.Urgent,
  [TicketSectionId.OtherPatientReports]: Section.NonUrgent,
  [TicketSectionId.TasksDue]: Section.TasksDue,
  [TicketSectionId.OverdueReports]: Section.Overdue,
  [TicketSectionId.TicketsAndCallbackRequests]: Section.Callback
};

export const workQueueTabs: WorkQueueTab[] = [WorkQueueTab.OpenItems, WorkQueueTab.ResolvedItems];
export const workQueueTabToNameMap: Record<WorkQueueTab, WorkQueueTabName> = {
  [WorkQueueTab.OpenItems]: WorkQueueTabName.OpenItems,
  [WorkQueueTab.ResolvedItems]: WorkQueueTabName.ResolvedItems
};
export const SECTION_HEADER_OFFSET = 30;
export const WQ_SECTION_WITHOUT_TICKETS_PAGE_NUMBER_SERVER_RESPONSE = -1;

export const workQueueToggleButtonOptions: {
  value: WorkQueueTab;
  label: WorkQueueTabName;
  testHook: string;
}[] = [
  {
    value: WorkQueueTab.OpenItems,
    label: WorkQueueTabName.OpenItems,
    testHook: workQueueTestSelectors.tab(WorkQueueTab.OpenItems)
  },
  {
    value: WorkQueueTab.ResolvedItems,
    label: WorkQueueTabName.ResolvedItems,
    testHook: workQueueTestSelectors.tab(WorkQueueTab.ResolvedItems)
  }
];

export const WQ_SIDEBAR_WIDTH = 240;
