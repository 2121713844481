import { FunctionComponent, ReactNode, useState } from 'react';

import { Typography } from '@mui/material';
import { AnalyticEventAction } from 'analytics';
import { useTrackSectionHeaderViewEvent } from 'analytics/events/section-header-view';

import { trackSectionUsageAnalyticsEvent } from 'analytics/events/section-usage';

import classNames from 'classnames';

import { Collapse } from 'reactstrap';

import { Testable } from 'utils/TypeUtils';

import OpenCloseArrow from './OpenCloseArrow';

import './CollapsibleSection.scss';

interface ICollapsibleSectionProps extends Testable {
  children?: ReactNode;
  trailing?: JSX.Element;
  trigger: JSX.Element | string;
  name: string;
  initiallyOpen?: boolean;
  onTrigger?: (args?: any) => void;
  onExpanded?: (args?: any) => void;
  onCollapsed?: (args?: any) => void;
  className?: string;
}

export const CollapsibleSection: FunctionComponent<ICollapsibleSectionProps> = ({
  children,
  trailing,
  trigger,
  name,
  onTrigger = () => {},
  onExpanded = () => {},
  onCollapsed = () => {},
  initiallyOpen = true,
  className,
  testHook
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(initiallyOpen);
  const classes = classNames('collapsible-section', className, {
    'is-collapsed': !isOpen
  });

  const { ref } = useTrackSectionHeaderViewEvent(name);

  const toggle = () => {
    onTrigger();

    setIsOpen((currentIsOpen) => {
      if (!currentIsOpen) {
        trackSectionUsageAnalyticsEvent({ action: AnalyticEventAction.Expand, value: name });
        onExpanded();
      }
      if (currentIsOpen) {
        trackSectionUsageAnalyticsEvent({ action: AnalyticEventAction.Collapse, value: name });
        onCollapsed();
      }
      return !currentIsOpen;
    });
  };

  return (
    <div className={classes} data-test-hook={testHook}>
      <div className="collapsible-section-header" ref={ref}>
        <div
          className="collapsible-section-trigger"
          onClick={toggle}
          data-test-hook={`trigger-${testHook}`}
        >
          <OpenCloseArrow isOpen={isOpen} />
          <Typography variant="h3" component="span">
            {trigger}
          </Typography>
        </div>
        <div>{trailing}</div>
      </div>

      <Collapse isOpen={isOpen}>{children}</Collapse>
    </div>
  );
};

export default CollapsibleSection;
