import { screen, waitFor } from '@testing-library/react';

import { Button } from 'tests/models/components/button/button';
import { editTicketModalSelectors } from 'tests/models/components/modal/edit-ticket-modal/edit-ticket-modal.selectors';
import { resolveAndCommentModalSelectors } from 'tests/models/components/modal/resolved-and-comment-modal/resolve-and-comment-modal.selectors';
import { PatientPageOpenItemsView } from 'tests/models/components/patient-page-views/patient-page-open-items-view/patient-page-open-items-view';
import { CallbackRequestTicket } from 'tests/models/components/ticket/callback-request-ticket';
import { OperatorTicket } from 'tests/models/components/ticket/operator-ticket';
import { OverdueReportTicket } from 'tests/models/components/ticket/overdue-report-ticket/overdue-report-ticket';
import { ReportTicket } from 'tests/models/components/ticket/report-ticket';
import { SymptomOperatorTicket } from 'tests/models/components/ticket/symptom-operator-ticket';
import { TicketTest } from 'tests/models/components/ticket/ticket';
import { ticketSelectors } from 'tests/models/components/ticket/ticket.selectors';
import {
  LogCallTicketAction,
  ReassignTicketAction,
  TicketAction,
  TicketActionTypeId,
  TicketActionViaActionsButton,
  TicketType,
  TicketWithActionsButton
} from 'tests/models/components/ticket/ticket.types';
import { WorkQueueOpenItemsTestView } from 'tests/models/components/work-queue-open-items-test-view/work-queue-open-items-test-view';
import { patientPageTestSelectors } from 'tests/models/pages/patient-page/patient-page.selectors';
import { sharedTestSelectors } from 'tests/shared/shared.selectors';

import { TicketSectionId } from 'views/WorkQueue/WorkQueue.types';

import { Section } from 'components/Ticket/TicketsContainers/TicketsContainers.constants';

export const sectionNameToSectionIdMap: Record<Section, TicketSectionId> = {
  [Section.Assigned]: TicketSectionId.AssignedToMe,
  [Section.Urgent]: TicketSectionId.UrgentPatientReports,
  [Section.NonUrgent]: TicketSectionId.OtherPatientReports,
  [Section.TasksDue]: TicketSectionId.TasksDue,
  [Section.Overdue]: TicketSectionId.OverdueReports,
  [Section.Callback]: TicketSectionId.TicketsAndCallbackRequests,
  [Section.ResolvedItems]: TicketSectionId.ResolvedItems
};

export const sectionIdToSectionNameMap: Record<TicketSectionId, Section> = {
  [TicketSectionId.AssignedToMe]: Section.Assigned,
  [TicketSectionId.UrgentPatientReports]: Section.Urgent,
  [TicketSectionId.OtherPatientReports]: Section.NonUrgent,
  [TicketSectionId.TasksDue]: Section.TasksDue,
  [TicketSectionId.OverdueReports]: Section.Overdue,
  [TicketSectionId.TicketsAndCallbackRequests]: Section.Callback,
  [TicketSectionId.ResolvedItems]: Section.ResolvedItems
};

const logCallTicketAction = async (action: LogCallTicketAction) => {
  const isPatientPage = window.location.href.includes('patient');

  if (isPatientPage) {
    const pathwayOption = new Button(
      sharedTestSelectors.actionsButton.pathwayOption(action.pathwayId)
    );
    pathwayOption.click();

    await waitFor(() => {
      expect(screen.getByTestId(patientPageTestSelectors.callLogger.container)).toBeDefined();
    });
  } else {
    throw new Error('Log call action is only available in patient page in test mode');
  }
};

const editTicketAction = async () => {
  await waitFor(() => {
    expect(screen.getByTestId(editTicketModalSelectors.container)).toBeDefined();
  });
};

const resolveAndCommentTicketAction = async () => {
  await waitFor(() => {
    expect(screen.getByTestId(resolveAndCommentModalSelectors.container)).toBeDefined();
  });
};

const reassignTicketAction = (action: ReassignTicketAction) => {
  const doctorOption = new Button(ticketSelectors.actions.reassignOption(action.doctorId));
  doctorOption.click();
};

const clickTicketActionsButton = async (ticket: TicketWithActionsButton) => {
  const ticketId = ticket.id;

  if (ticket.actionsButton.htmlElement) {
    ticket.actionsButton.click();

    await waitFor(() => {
      expect(screen.getByTestId(ticketSelectors.actions.menu)).toBeDefined();
    });
  } else {
    throw new Error(
      `No actions button found for ticket with id: ${ticketId} in section ${ticket.sectionName}`
    );
  }
};

const clickTicketActionButton = (ticket: TicketTest, action: TicketAction) => {
  const ticketId = ticket.id;

  const actionButton = new Button(ticketSelectors.actions.getActionButton(action.type, ticketId));

  if (actionButton.htmlElement) {
    actionButton.click();
  } else {
    throw new Error(
      `No action button found for ticket with id: ${ticketId} in section ${ticket.sectionName}`
    );
  }
};

export const chooseActionViaActionsButton = async (
  ticket: TicketWithActionsButton,
  action: TicketActionViaActionsButton
) => {
  await clickTicketActionsButton(ticket);
  clickTicketActionButton(ticket, action);

  switch (action.type) {
    case TicketActionTypeId.LogCall:
      await logCallTicketAction(action);
      break;

    case TicketActionTypeId.Edit:
      editTicketAction();
      break;

    case TicketActionTypeId.Reassign:
      reassignTicketAction(action);
      break;

    case TicketActionTypeId.ResolveAndComment:
      await resolveAndCommentTicketAction();
      break;
  }

  if (action.elementSelectorToWaitFor) {
    await waitFor(() => {
      expect(screen.getByTestId(action.elementSelectorToWaitFor!)).toBeDefined();
    });
  }
};

export const covertTicketHtmlElementsToTestObjects = (
  ticketHtmlElements: HTMLElement[],
  view: WorkQueueOpenItemsTestView | PatientPageOpenItemsView
): TicketTest[] =>
  ticketHtmlElements.map((ticketContainer) => {
    const ticketTestHook = ticketContainer.getAttribute('data-test-hook')!;
    const ticketType = ticketTestHook.split('-')[3] as TicketType;

    switch (ticketType) {
      case TicketType.Operator:
        return new OperatorTicket(ticketTestHook, view);
      case TicketType.SymptomOperator:
        return new SymptomOperatorTicket(ticketTestHook, view);
      case TicketType.Patient:
        return new ReportTicket(ticketTestHook, view);
      case TicketType.CallbackRequest:
        return new CallbackRequestTicket(ticketTestHook, view);
      case TicketType.Overdue:
        return new OverdueReportTicket(ticketTestHook, view);
      case TicketType.Task:
        return new SymptomOperatorTicket(ticketTestHook, view);
      default:
        throw new Error(`Invalid ticket type: ${ticketType}`);
    }
  });
