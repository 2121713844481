import { FC } from 'react';

import { observer } from 'mobx-react';
import { sharedTestSelectors } from 'tests/shared/shared.selectors';

import { ExtendedTicket } from 'utils/TicketClusteringUtils';

import { TicketSectionId } from 'views/WorkQueue/WorkQueue.types';

import { Section } from 'components/Ticket/TicketsContainers/TicketsContainers.constants';

import TicketsSection from './TicketsSection';

interface Props {
  tickets: ExtendedTicket[];
  ticketRefsCallback?: (node: HTMLElement, ticketId: number) => void;
}

const TicketsSectionAssigned: FC<Props> = ({ tickets, ticketRefsCallback }) => (
  <TicketsSection
    section={Section.Assigned}
    tickets={tickets}
    shouldHighlight
    ticketRefsCallback={ticketRefsCallback}
    testHook={sharedTestSelectors.ticketSection.container(TicketSectionId.AssignedToMe)}
  />
);

export default observer(TicketsSectionAssigned);
