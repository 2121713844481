import { TicketSubTypeOption } from 'utils/TicketType.utils';

import ClusteredTaskTicket from 'models/ClusteredTaskTicket';
import Patient from 'models/Patient';

import PatientProvider from 'models/PatientProvider';

import { ReportType } from 'models/QuestionnaireAnswer';
import { SelectOption } from 'models/SelectOption';

import { TicketSortOrder } from 'models/Settings';
import Ticket from 'models/Ticket';
import { TicketStatus } from 'models/Ticket';

import { SPECIAL_FILTERS } from 'views/Filters/filters.constants';

import { TicketTypeRequestParam } from 'views/Filters/filters.types';

export enum WorkQueueItemType {
  PatientTicket = 'patientTicket',
  OperatorTicket = 'operatorTicket',
  CallbackRequestTicket = 'callbackRequestTicket',
  TaskTicket = 'taskTicket',
  TaskTicketClustered = 'taskTicketClustered',
  OverdueReport = 'overdueReport'
}

export interface WorkQueueSection<T extends WorkQueueItemType, K = any> {
  totalPages: number;
  totalItems: number;
  pageNumber: number;
  items: { itemType: T; itemData: K }[];
}

export type AssignedToMeItemType =
  | WorkQueueItemType.PatientTicket
  | WorkQueueItemType.OperatorTicket
  | WorkQueueItemType.CallbackRequestTicket
  | WorkQueueItemType.TaskTicket;

export type UrgentPatientReportsItemType =
  | WorkQueueItemType.PatientTicket
  | WorkQueueItemType.OperatorTicket;

export type TicketsAndCallbackRequestsItemType =
  | WorkQueueItemType.OperatorTicket
  | WorkQueueItemType.CallbackRequestTicket;

export type ResolvedItemsItemType =
  | WorkQueueItemType.PatientTicket
  | WorkQueueItemType.OperatorTicket
  | WorkQueueItemType.CallbackRequestTicket
  | WorkQueueItemType.TaskTicket;

export type AssignedToMeSection<T = any> = WorkQueueSection<AssignedToMeItemType, T>;

export type UrgentPatientReportsSection<T = any> = WorkQueueSection<
  UrgentPatientReportsItemType,
  T
>;

export type TicketsAndCallbackRequestsSection<T = any> = WorkQueueSection<
  TicketsAndCallbackRequestsItemType,
  T
>;

export type OtherPatientReportsSection<T = any> = WorkQueueSection<
  WorkQueueItemType.PatientTicket,
  T
>;

export type TasksDueSection<T = any> = WorkQueueSection<
  WorkQueueItemType.TaskTicketClustered,
  T
> & {
  totalPatients: number;
};

export type ResolvedItemsSection<T = any> = WorkQueueSection<ResolvedItemsItemType, T>;

export type OverdueReportsItemType = WorkQueueItemType.OverdueReport;

export type OverdueReportsSection = WorkQueueSection<OverdueReportsItemType, { patientId: number }>;

export enum TicketSectionId {
  AssignedToMe = 'assignedToMe',
  UrgentPatientReports = 'urgentPatientReports',
  TicketsAndCallbackRequests = 'ticketsAndCallbackRequests',
  TasksDue = 'tasksDue',
  OtherPatientReports = 'otherPatientReports',
  OverdueReports = 'overdueReports',
  ResolvedItems = 'resolvedItems'
}

export type WorkQueueOpenItemsSectionName = Exclude<TicketSectionId, TicketSectionId.ResolvedItems>;

export interface WorkQueueSections {
  [TicketSectionId.AssignedToMe]?: AssignedToMeSection<Ticket>;
  [TicketSectionId.UrgentPatientReports]?: UrgentPatientReportsSection<Ticket>;
  [TicketSectionId.TicketsAndCallbackRequests]?: TicketsAndCallbackRequestsSection<Ticket>;
  [TicketSectionId.OtherPatientReports]?: OtherPatientReportsSection<Ticket>;
  [TicketSectionId.TasksDue]?: TasksDueSection<ClusteredTaskTicket>;
  [TicketSectionId.OverdueReports]?: OverdueReportsSection;
  [TicketSectionId.ResolvedItems]?: ResolvedItemsSection;
}

export interface WorkQueueSectionStatusData {
  newItemsCount: number;
  maxUrgency: number | null;
}

export interface WorkQueueRequestSection {
  name: TicketSectionId;
  recordsPerPage: number;
  pageNumber: number;
  sort: TicketSortOrder;
  filters?: WorkQueueSectionFilters;
}

export enum ItemCategory {
  NonEpisodeTasks = 'nonEpisodeTasks',
  EpisodeTasks = 'episodeTasks',
  OverdueSymptomReports = 'overdueSymptomReports',
  OverdueOralReports = 'overdueOralReports'
}

// for the actual ui filters
export interface WorkQueueFilterTypes {
  searchTerm?: string;
  patientTags?: SelectOption<number>[];
  locations?: SelectOption<number>[];
  providers?: SelectOption<PatientProvider>[];
  assignees?: SelectOption<number | SPECIAL_FILTERS>[];
  // Tasks
  fromDate?: Date;
  toDate?: Date;
  episodeIds?: SelectOption<number>[];
  taskSearchTerm?: string;
  lastNameFirstLetter?: SelectOption<string>[];
  status?: SelectOption<TicketStatus>[];
  role?: SelectOption<number>[];
  owners?: SelectOption<number>[];

  // Tickets
  ticketType?: TicketSubTypeOption[];
}

// for the request body
export type WorkQueueRequestFilters = {
  // General
  nameOrMrn?: string;
  providers?: string[];
  locations?: number[];
  assignees?: number[];
  resolvedByIds?: number[];
  patientId?: number;
  patientIds?: number[];
  lastRetrievedTimestamp?: number;
  patientTags?: number[];
  // Tasks
  fromDate?: string | null;
  toDate?: string | null;
  episodes?: number[];
  taskSearchTerm?: string;
  lastNameFirstLetter?: string[];
  taskStatuses?: TicketStatus[];
  roles?: number[];
  owners?: number[];
  // Tickets
  ticketStatuses?: TicketStatus[];
  ticketTypes?: TicketTypeRequestParam[];
  // Report Types - derived from item types filter
  reportTypes?: ReportType[];
  // Item Type Categories - derived from item types filter
  itemCategories?: ItemCategory[];
};

export interface WorkQueueRequestBody {
  sections: WorkQueueRequestSection[];
  filters: Partial<WorkQueueRequestFilters>;
}

export interface WorkQueueBulkRequestReportBody {
  patientIds: number[];
}

export interface ParsedWorkQueueResponse {
  sections: WorkQueueSections;
  patients: Patient[];
}

export interface WorkQueueSectionFilters {
  lastRetrievedTimestamp: number | null;
}

export enum WorkQueueTab {
  OpenItems = 'open-items',
  ResolvedItems = 'resolved-items'
}

export enum WorkQueueTabName {
  OpenItems = 'Open Items',
  ResolvedItems = 'Resolved Items'
}
