import { FC, MutableRefObject, ReactElement } from 'react';

import { AnalyticEventAction } from 'analytics';

import { trackRequestReportAnalyticsEvent } from 'analytics/events/request-report';

import classNames from 'classnames';

import { observer } from 'mobx-react';
import { overdueReportTicketSelectors } from 'tests/models/components/ticket/overdue-report-ticket/overdue-report-ticket.selectors';

import { fromNow } from 'utils/DateUtils';

import Patient from 'models/Patient';
import { ProtocolType } from 'models/ScheduledProtocol';

import 'views/Widgets/RequestReportDropdown.scss';

import { ITooltipOption, Tooltip, TooltipSelect } from 'components/Tooltip';
import { TooltipController, TooltipTrigger } from 'components/Tooltip/Tooltip.types';

interface IRequestReportButtonProps {
  patient: Patient;
  onSmsClicked: (shouldOpenSmsBlockedPopup: boolean, isSmsOptionDisabled: boolean) => void;
  onPhoneClicked: () => void;
  isMobile?: boolean;
  preferred?: string;
  disabled?: boolean;
  label?: ReactElement;
  tooltipReference?: MutableRefObject<Element>;
  tooltipController?: TooltipController;
  ticketId?: number;
}

export const RequestReportDropdown: FC<IRequestReportButtonProps> = ({
  patient,
  tooltipController,
  tooltipReference,
  label,
  isMobile,
  preferred,
  onSmsClicked,
  onPhoneClicked,
  disabled
}) => {
  const getOptions = (): ITooltipOption[] => {
    let lastSentSMSCaption;
    let lastSentAutomatedRequestCaption;
    const requestsAreForSameReport =
      patient.mainScheduledProtocol &&
      patient.mainScheduledProtocol.name === patient.lastReportNameRequested;
    if (patient.hasBeenRemindedBySms && requestsAreForSameReport) {
      lastSentSMSCaption = fromNow(patient.lastSmsRequestReportTime);
    }
    if (patient.hasBeenSentAutomatedReportRequest && requestsAreForSameReport) {
      lastSentAutomatedRequestCaption = `Attempt ${patient.requestCallAttempts}: ${fromNow(
        patient.lastAutomatedRequestReportTime
      )}`;
    }

    const canSendSms = isMobile && !patient.isSMSDisabled;
    const shouldOpenSmsBlockedPopup = !canSendSms && isMobile;
    const isSmsOptionDisabled = !canSendSms && !isMobile;

    return [
      {
        text: (
          <>
            <div className={classNames({ bold: preferred === ProtocolType.mobile })}>
              SMS Reminder
            </div>
            {!canSendSms && (
              <div className="request-report-option-subtitle">
                {!isMobile ? 'Unavailable - Landline' : 'SMS Blocked - Click for Info'}
              </div>
            )}
            {canSendSms && lastSentSMSCaption && (
              <div className="request-report-option-subtitle">{lastSentSMSCaption}</div>
            )}
          </>
        ),
        onClick: () => {
          trackRequestReportAnalyticsEvent({
            action: AnalyticEventAction.SingleReport,
            type: 'sms',
            patient_id: patient.id,
            value: shouldOpenSmsBlockedPopup ? 'blocked' : undefined
          });

          onSmsClicked(shouldOpenSmsBlockedPopup as boolean, isSmsOptionDisabled);
        },
        testHook: overdueReportTicketSelectors.smsReminderButton(patient.id),
        disabled: isSmsOptionDisabled
      },
      {
        text: (
          <>
            <div className={classNames({ bold: preferred === ProtocolType.phone })}>
              Automated Report Call
            </div>
            {lastSentAutomatedRequestCaption && (
              <div className="request-report-option-subtitle">
                {lastSentAutomatedRequestCaption}
              </div>
            )}
          </>
        ),
        onClick: () => {
          trackRequestReportAnalyticsEvent({
            action: AnalyticEventAction.SingleReport,
            type: 'ivr',
            patient_id: patient.id
          });

          onPhoneClicked();
        },
        isVisible: !patient.hasOralProtocol,
        testHook: overdueReportTicketSelectors.automatedReportCallButton(patient.id)
      }
    ];
  };

  return (
    <Tooltip
      trigger={TooltipTrigger.CLICK}
      reference={tooltipReference}
      controller={tooltipController}
      disabled={disabled}
      label={<>{label}</>}
      testHook={overdueReportTicketSelectors.requestMenu(patient.id)}
    >
      <TooltipSelect options={getOptions()} />
    </Tooltip>
  );
};

export default observer(RequestReportDropdown);
