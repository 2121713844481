// @ts-strict-ignore

import { useCallback } from 'react';

import { AnalyticEventAction } from 'analytics';

import { trackPathwayMenuAnalyticsEvent } from 'analytics/events/pathway-menu';

import { isEmpty } from 'lodash/fp';

import { sharedTestSelectors } from 'tests/shared/shared.selectors';

import { useStores } from 'mobx/hooks/useStores';

import {
  alertAttentionByUrgency,
  symptomTicketUrgencyToAlertAttentionMap
} from 'utils/ClinicianAlertsUtils';

import {
  NumericReportedCauseFromServer,
  SeverityReportedCauseFromServer
} from 'parsers/ParseServerResponseService';

import ClinicianAlert, { PopulationType } from 'models/ClinicianAlert';

import AnyCauseCondition from 'models/Conditions/AnyCauseCondition';

import SpecificCauseCondition, { DxCode } from 'models/Conditions/SpecificCauseCondition';

import { PathwayBasicInfo, PathwaySearchResult } from 'models/PathwayTemplates';

import Patient from 'models/Patient';

import Ticket from 'models/Ticket';

import { SymptomType } from 'models/TicketTypes';

import { PathwayType } from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.types';

import { usePatientModel } from 'components/Patient/usePatientModel';
import {
  CallAndPathwaysDropDownProps,
  PathwaySelectType
} from 'components/Ticket/TicketRow/CallAndPathwaysDropDown';
import PathwayWithDxAlertOption from 'components/Ticket/TicketRow/PathwayWithDxAlertOption';
import { PathwayWithKeywords } from 'components/Ticket/TicketRow/PathwayWithKeywords';
import useTicketOverviewContext from 'components/Ticket/TicketsContainers/useTicketOverviewContext';
import { DividerPlacement, ITooltipOption } from 'components/Tooltip';

const GROUP_HEADER_TITLE = 'DID YOU MEAN?';

/*
     we have 2 sections:
        1. pathways with search query match by pathway's name.
        2. pathways with search query match by pathway's keyword/s
           in each section we have a separation:
        1. pathways with dx alerts
        2. pathways without dx alerts
 */
export const usePathwayOptions = (
  props: Partial<CallAndPathwaysDropDownProps>,
  searchValue: string,
  isBulk: boolean = false
) => {
  const {
    onSelect,
    showSelected,
    allowNoPathway,
    tooltipController,
    ticketSectionCurrentPage = 0,
    ticketIndex = 0,
    ticket
  } = props;
  const matchedSymptomPathwaysByName: ITooltipOption[] = [];
  const matchedCareManagementPathwaysByName: ITooltipOption[] = [];
  const matchedPathwaysByNameWithDxAlerts: ITooltipOption[] = []; //we need this because we have a divider
  const matchedPathwaysByKeywords: ITooltipOption[] = [];

  const { pathwaysStore, alertsStore, constantsStore } = useStores();
  const { getPatientSymptomOperatorTickets } = useTicketOverviewContext();
  const patient = usePatientModel(props.patientId);

  const getPathwayOption = (
    pathway: PathwayBasicInfo,
    type: PathwaySelectType
  ): ITooltipOption => ({
    id: pathway.id,
    text: pathway.name,
    onClick: () => {
      trackPathwayMenuAnalyticsEvent({
        action: AnalyticEventAction.Select,
        value: type,
        ticket_ids: props.ticketIds,
        page_number: ticketSectionCurrentPage + 1,
        item_index: ticketIndex + 1,
        bulk_item: isBulk
      });
      onSelect(pathway);
    },
    isSelected: pathway.name === pathwaysStore.currentPathwayInfo?.name && showSelected,
    testHook: `${pathway.id}-option`
  });

  const getDxCodes = useCallback(
    (
      condition: SpecificCauseCondition | AnyCauseCondition,
      symptom: SymptomType,
      alert: ClinicianAlert,
      patient: Patient,
      descriptionsFromAlertsMap: Map<string, DxCode>
    ) => {
      const isMatch = alertsStore.isConditionMatchForSymptom(condition, symptom.id);
      const shouldSaveDxCode =
        alert.population.type === PopulationType.PatientsWithDiagnosis &&
        condition instanceof SpecificCauseCondition &&
        isMatch &&
        condition.hasMatchingDx(patient.dxCodeIds);
      if (shouldSaveDxCode) {
        const alertingDxCodes = (condition as SpecificCauseCondition).matchingDxCodes(
          patient.dxCodeIds
        );
        if (alertingDxCodes.length) {
          alertingDxCodes.forEach(
            (codeItem) =>
              !descriptionsFromAlertsMap.has(codeItem.code) &&
              descriptionsFromAlertsMap.set(codeItem.code, codeItem)
          );
        }
      }
    },
    [alertsStore]
  );

  const getAlertSeverityForPatient = useCallback(
    (
      pathwayId: string,
      patient: Patient
    ): { alertSeverity: number; descriptionsFromAlerts: string[] } => {
      let alertSeverity = 0;

      const descriptionsFromAlertsMap: Map<string, DxCode> = new Map();
      const descriptionsFromAlerts: string[] = [];

      const symptomsForPathway = constantsStore.getSymptomsByPathwayId(pathwayId);
      symptomsForPathway.forEach((symptom) => {
        alertsStore.alerts.forEach((alert) => {
          alert.conditions.forEach((condition) => {
            if (
              patient &&
              (condition instanceof SpecificCauseCondition ||
                condition instanceof AnyCauseCondition)
            ) {
              const patientSymptomOperatorTickets = getPatientSymptomOperatorTickets(patient);
              patientSymptomOperatorTickets.forEach((ticket: Ticket) => {
                const operatorTicket = ticket.operatorTicket;
                operatorTicket.subTicketTypeIds.forEach((symptomId) => {
                  if (symptomId === symptom.id) {
                    if (alert.population.type !== PopulationType.PatientsWithDiagnosis) {
                      alertSeverity =
                        symptomTicketUrgencyToAlertAttentionMap[operatorTicket.urgency];
                    } else {
                      getDxCodes(condition, symptom, alert, patient, descriptionsFromAlertsMap);
                    }
                  }
                });
              });
              patient.unacknowledgedReports.forEach((report) => {
                if (
                  report.answer.causes?.some((cause) => {
                    const checkReportedSeverityCause =
                      (cause as SeverityReportedCauseFromServer).severity >=
                        condition.getBottomThreshold() &&
                      (condition instanceof AnyCauseCondition ||
                        condition.getId() === cause.causeId);

                    const checkReportedNumericCause =
                      (cause as NumericReportedCauseFromServer).value !== 'iDontKnow' &&
                      ((cause as NumericReportedCauseFromServer).value as number) >=
                        condition.getBottomThreshold() &&
                      (condition instanceof SpecificCauseCondition ||
                        condition.getId() === cause.causeId) &&
                      alert.population.type === PopulationType.PatientsWithSpecificDrug;

                    return (
                      cause.causeId === symptom.id &&
                      (checkReportedSeverityCause || checkReportedNumericCause)
                    );
                  })
                ) {
                  if (alert.population.type !== PopulationType.PatientsWithDiagnosis) {
                    const alertAttention = alertAttentionByUrgency[alert.urgency];
                    alertSeverity = Math.max(alertSeverity, alertAttention);
                  } else {
                    getDxCodes(condition, symptom, alert, patient, descriptionsFromAlertsMap);
                  }
                }
              });
            }
          });
        });
      });
      if (!isEmpty(descriptionsFromAlertsMap)) {
        descriptionsFromAlertsMap.forEach((dxCode) => {
          descriptionsFromAlerts.push(dxCode.text);
        });
      }
      return { alertSeverity, descriptionsFromAlerts };
    },
    [alertsStore.alerts, constantsStore, getDxCodes, getPatientSymptomOperatorTickets]
  );

  pathwaysStore.orderedPathwayNames(searchValue).forEach((pathway: PathwaySearchResult) => {
    const { alertSeverity, descriptionsFromAlerts } = getAlertSeverityForPatient(
      pathway.id,
      patient
    );

    const hasDxAlert = descriptionsFromAlerts.length > 0 || alertSeverity > 0;

    if (hasDxAlert) {
      //pathways with alerts - match by name
      if (pathway.isMatchByName) {
        matchedPathwaysByNameWithDxAlerts.push({
          ...getPathwayOption(pathway, PathwaySelectType.Alert),
          text: (
            <PathwayWithDxAlertOption
              pathway={pathway}
              tags={descriptionsFromAlerts}
              isVisible={tooltipController.visible}
              alertSeverity={alertSeverity}
              searchValue={searchValue}
            />
          )
        });
        return;
      }

      //pathways with alerts - match by keywords
      matchedPathwaysByKeywords.unshift({
        ...getPathwayOption(pathway, PathwaySelectType.Recommended),
        text: (
          <PathwayWithDxAlertOption
            pathway={pathway}
            tags={descriptionsFromAlerts}
            isVisible={tooltipController.visible}
            alertSeverity={alertSeverity}
            searchValue={searchValue}
            keywords={pathway.matchedKeywords}
          />
        ),
        groupHeader: matchedPathwaysByKeywords.length === 0 ? GROUP_HEADER_TITLE : ''
      });
      return;
    }

    //pathways without alerts - match by name
    if (pathway.isMatchByName) {
      const matchedPathwaysTypeByNameArray =
        pathway.type !== PathwayType.CareManagement
          ? matchedSymptomPathwaysByName
          : matchedCareManagementPathwaysByName;

      matchedPathwaysTypeByNameArray.push({
        ...getPathwayOption(pathway, PathwaySelectType.Regular),
        hasHighlightOnText: true
      });
      return;
    }

    //pathways without alerts - match by keywords
    matchedPathwaysByKeywords.push({
      ...getPathwayOption(pathway, PathwaySelectType.Recommended),
      text: (
        <PathwayWithKeywords
          pathway={pathway}
          searchValue={searchValue}
          keywords={pathway.matchedKeywords}
        />
      ),
      groupHeader: matchedPathwaysByKeywords.length === 0 ? GROUP_HEADER_TITLE : ''
    });
  });

  //add the divider at the bottom of the pathways with dx alerts section
  if (matchedPathwaysByNameWithDxAlerts.length) {
    matchedPathwaysByNameWithDxAlerts[matchedPathwaysByNameWithDxAlerts.length - 1].withDivider =
      true;
    matchedPathwaysByNameWithDxAlerts[
      matchedPathwaysByNameWithDxAlerts.length - 1
    ].dividerPlacement = DividerPlacement.Bottom;
  }

  if (matchedSymptomPathwaysByName.length) {
    matchedSymptomPathwaysByName[0].groupHeader = 'Symptoms';
  }

  if (matchedCareManagementPathwaysByName.length) {
    matchedCareManagementPathwaysByName[0].groupHeader = 'Care Management';
  }

  const showCareManagementPathwaysFirst =
    ticket && !ticket.isSymptomOperatorTicket && !ticket.isPatientTicket;

  const sortedMatchedPathwaysByName = showCareManagementPathwaysFirst
    ? [...matchedCareManagementPathwaysByName, ...matchedSymptomPathwaysByName]
    : [...matchedSymptomPathwaysByName, ...matchedCareManagementPathwaysByName];

  if (allowNoPathway) {
    sortedMatchedPathwaysByName.unshift({
      id: 'no-pathway',
      text: 'Call Without Pathway',
      testHook: sharedTestSelectors.actionsButton.pathwayOption(),
      onClick: () => {
        trackPathwayMenuAnalyticsEvent({
          action: AnalyticEventAction.Select,
          value: PathwaySelectType.WithoutPathway,
          ticket_ids: props.ticketIds,
          page_number: ticketSectionCurrentPage + 1,
          item_index: ticketIndex + 1,
          bulk_item: isBulk
        });
        onSelect(null);
      }
    });
  }

  return [
    ...matchedPathwaysByNameWithDxAlerts,
    ...sortedMatchedPathwaysByName,
    ...matchedPathwaysByKeywords
  ];
};
