// @ts-strict-ignore
import moment from 'moment';

import { IOptOutSubmitObject } from 'views/Modals/OptOutModal';

import { OptOutReason } from './OptOutReason';

export class PatientOptOut {
  id: number;
  institutionId: number;
  patientOptOutReason: OptOutReason;
  details: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(
    id: number,
    patientOptOutReason: OptOutReason,
    details: string,
    createdAt: Date,
    updatedAt: Date
  ) {
    this.id = id;
    this.patientOptOutReason = patientOptOutReason;
    this.details = details;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }

  static fromJson(json: any) {
    return new PatientOptOut(
      json.id,
      OptOutReason.fromJson(json.patientOptOutReason),
      json.details,
      json.created_at || json.createdAt,
      json.updated_at || json.updatedAt
    );
  }

  get formattedCreatedDate(): string {
    return moment(this.createdAt).format('L');
  }

  get requestFields(): IOptOutSubmitObject {
    return {
      reasonId: this.patientOptOutReason.id,
      name: this.patientOptOutReason.name,
      details: this.details
    };
  }
}
