import { useState, FC, useEffect } from 'react';

import { Box, css, styled } from '@mui/material';
import { AnalyticEventAction } from 'analytics/AnalyticEventAction';
import { Dialogs } from 'analytics/events/dialog';
import { trackFilterSetModalAnalyticsEvent } from 'analytics/events/filter-set-modal';
import { useForm, useController } from 'react-hook-form';

import { workQueueTestSelectors } from 'tests/models/pages/work-queue/work-queue-page.selectors';

import { useStores } from 'mobx/hooks/useStores';

import { showToast } from 'utils/UserMessageUtils';

import { HttpStatusCode } from 'constants/httpStatusCode.const';

import { UseFilterSetsMethods } from 'hooks/useFilterSets';

import { RHFStyledInput } from 'views/Widgets/StyledInput';

import StyledToggleButton, { LabelPosition } from 'views/Widgets/StyledToggleButton';

import Icon from 'components/Icons/Icon';

import { MessageDialog } from 'components/UIkit/atoms/Dialog';
import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';
import { Text } from 'components/UIkit/atoms/Text';

import { SearchFiltersType } from './filters.types';

export interface EditFilterSetResult {
  success: boolean;
  generalError?: string;
  nameError?: string;
}

export interface Props {
  open: boolean;
  mode: 'create' | 'edit';
  name: string;
  isShared: boolean;
  selectedFilters: SearchFiltersType;
  onClose: () => void;
  useFilterSetsMethods: UseFilterSetsMethods;
  id?: number;
}

interface FilterSetForm {
  name: string;
  isShared: boolean;
}

export const FilterSetDialog: FC<Props> = ({
  open,
  mode,
  id,
  name,
  isShared,
  selectedFilters,
  onClose,
  useFilterSetsMethods: { updateFilterSet, chooseFilterSet, saveNewFilterSet, deleteFilterSet }
}) => {
  const { userStore } = useStores();

  const canShare = userStore.isManager;
  const isEditMode = mode === 'edit';

  useEffect(() => {
    if (open) {
      trackFilterSetModalAnalyticsEvent({
        action: AnalyticEventAction.Open,
        value: isEditMode ? 'edit' : 'create',
        is_shared: isShared
      });
    }
  }, [open, isEditMode, isShared]);

  const defaultValues = {
    name: name ?? '',
    isShared: Boolean(isShared)
  };

  const methods = useForm<FilterSetForm>({
    defaultValues
  });

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = methods;

  const { field } = useController({
    name: 'isShared',
    control
  });

  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async (data: FilterSetForm) => {
    const targetName = data.name?.trim();
    if (!targetName) {
      methods.setError('name', {}, { shouldFocus: true });
      return;
    }
    const targetIsShared = canShare ? data.isShared : false;
    try {
      setIsSaving(true);
      if (isEditMode) {
        await updateFilterSet({
          id,
          name: targetName,
          filters: selectedFilters,
          isShared: targetIsShared
        });
        showToast({ message: `${targetName} Updated` });
      } else {
        const id = await saveNewFilterSet({
          name: targetName,
          filters: selectedFilters,
          isShared: targetIsShared
        });
        chooseFilterSet(id);
        showToast({ message: `${targetName} Created` });
      }
      trackFilterSetModalAnalyticsEvent({
        action: AnalyticEventAction.Save,
        value: isEditMode ? 'edit' : 'create',
        is_shared: targetIsShared,
        share_added: !isShared && targetIsShared,
        share_removed: isShared && !targetIsShared,
        name_changed: targetName !== name
      });
      onClose();
    } catch (error) {
      if (error?.httpFailure?.statusCode === HttpStatusCode.CONFLICT) {
        methods.setError('name', { message: 'Name is already in use' }, { shouldFocus: true });
      } else {
        throw error;
      }
    } finally {
      setIsSaving(false);
    }
  };

  const handleDelete = async () => {
    if (id !== undefined) {
      try {
        setIsSaving(true);
        await deleteFilterSet(id);
        trackFilterSetModalAnalyticsEvent({
          action: AnalyticEventAction.Delete,
          is_shared: isShared
        });
        setIsDeleteConfirmOpen(false);
        onClose();
        showToast({ message: `${name} Deleted` });
      } finally {
        setIsSaving(false);
      }
    }
  };

  const handleCancel = () => {
    trackFilterSetModalAnalyticsEvent({
      action: AnalyticEventAction.Cancel,
      is_shared: isShared
    });
    onClose();
  };

  const nameFieldValue = methods.watch('name');
  const getSharingWarningMessage = () => {
    if (canShare) {
      if (field.value && !isShared) {
        return 'Proceeding with this action will save changes and enable all users in the practice to use this Filter Set.';
      }
      if (!field.value && isShared) {
        return 'Proceeding with this action will save changes. Anybody currently using this Filter Set will lose access to it.';
      }
      if (field.value && isShared && name !== nameFieldValue?.trim()) {
        return 'Proceeding with this action will save changes and share the Filter Set with all users.';
      }
    }
    return null;
  };
  const sharingWarningMessage = getSharingWarningMessage();

  const getSaveButtonText = (): string => {
    if (canShare) {
      if (!isShared && field.value) {
        return 'Save & Share';
      }
      if (isShared && !field.value) {
        return 'Save & Revoke Share';
      }
    }
    return 'Save';
  };

  return (
    <>
      <FormModal
        size="small"
        defaultValues={defaultValues}
        methods={methods}
        isOpen={open}
        title={`Filter Set${isEditMode ? `: ${name}` : ''}`}
        confirmActions={[
          {
            onClick: handleSubmit(handleSave),
            text: getSaveButtonText(),
            disabled: isSaving,
            testHook: 'filters-sets-modal-submit-button'
          }
        ]}
        closeAction={{
          onClick: handleCancel,
          disabled: isSaving
        }}
        secondaryAction={
          isEditMode
            ? {
                type: 'button',
                onClick: () => setIsDeleteConfirmOpen(true),
                text: 'Delete',
                disabled: isSaving
              }
            : undefined
        }
        testHook="filters-sets-modal"
      >
        <Box mb={40}>
          <RHFStyledInput
            testHook="filters-sets-modal-name-input"
            name="name"
            label="Filter Set Name"
            register={methods.register}
            isRequired
            error={Boolean(errors.name)}
            errorMessage={errors.name?.message}
          />
          {canShare && (
            <Box mt={20}>
              <StyledToggleButton
                label={
                  <Text variant="body1">
                    Share with Practice
                    <StyledShareIcon />
                  </Text>
                }
                checked={field.value}
                onChange={field.onChange}
                labelPosition={LabelPosition.RIGHT}
                testHook={workQueueTestSelectors.openItemsTab.filterSetModal.isSharedToggle}
              />
            </Box>
          )}
          {canShare && (
            <StyledWarningBox>
              {/* StyledWarningBox preserves height for the warning text even when it's empty to prevent layout changes. */}
              {sharingWarningMessage && <Text variant="body2">{sharingWarningMessage}</Text>}
            </StyledWarningBox>
          )}
        </Box>
      </FormModal>

      <MessageDialog
        id={Dialogs.DeleteFilterSet}
        isOpen={isDeleteConfirmOpen}
        handleClose={() => setIsDeleteConfirmOpen(false)}
        title={`Delete ${isShared ? 'Shared Filter Set' : 'Filter Set'} "${name}"?`}
        primaryActionProps={{
          text: 'Delete',
          onClick: handleDelete,
          disabled: isSaving
        }}
        secondaryActionProps={{
          text: 'Cancel',
          onClick: () => {
            setIsDeleteConfirmOpen(false);
          },
          disabled: isSaving
        }}
        testHook={workQueueTestSelectors.openItemsTab.filterSetModal.filterSetDeleteDialog}
      >
        This action cannot be undone.
        {isShared && (
          <>
            <br />
            Anybody currently using this Filter Set will lose access to it.
          </>
        )}
      </MessageDialog>
    </>
  );
};

const StyledShareIcon = styled(Icon.Share)(
  ({ theme }) => css`
    margin-left: ${theme.spacing(8)};
  `
);

const StyledWarningBox = styled(Box)(
  ({ theme }) => css`
    margin-top: ${theme.spacing(16)};
    margin-bottom: -${theme.spacing(16)};
    min-height: 42px;
  `
);
