// @ts-strict-ignore
import { useStores } from 'mobx/hooks/useStores';

import Call, { getCallInfo, ICall, IncludeSummaryInEmrNote } from 'models/Call';
import CallLogIntervalAuditTrailRecord from 'models/CallLogIntervalAuditTrailRecord';
import Patient from 'models/Patient';
import { UserPreferences } from 'models/UserModel';

import { disabledSummaryText } from 'views/Widgets/CallLogging/CallLoggingSummary';

type UsePrepareCallReturnType = (isDraft: boolean, isCallAttempt: boolean) => Call;

//for submitting or creating/updating draft in call logger
export const usePrepareCall = (
  patient: Patient,
  userPreferences: UserPreferences,
  auditTrail: CallLogIntervalAuditTrailRecord[]
): UsePrepareCallReturnType => {
  const { callLoggingStore, pathwaysStore, userStore, constantsStore } = useStores();

  const prepareCall = (isDraft: boolean = false, isCallAttempt: boolean = false) => {
    const {
      id,
      callDuration,
      note,
      patientInitiated,
      durationIntervals,
      callReasons,
      ticketIds,
      topicsDiscussed,
      summary,
      includeSummaryInEmrNote,
      smartSummaryId,
      isSummaryManuallyEdited,
      clinician,
      homeCareInstructionsDraft,
      homeCareInstructions
    } = callLoggingStore.currentCall;
    const { lastReport } = patient;
    const orderedPathwayAnswers = pathwaysStore.orderedPathwayAnswers;

    const lastInterval = durationIntervals![durationIntervals!.length - 1];
    lastInterval.isCallAttempt = isCallAttempt;

    const callSummaryOptionalProperties: {
      summary?: string;
      smartSummaryId?: number | null;
      includeSummaryInEmrNote?: IncludeSummaryInEmrNote;
      isSummaryManuallyEdited?: boolean;
    } = {};

    if (userPreferences.generateSmartSummary) {
      callSummaryOptionalProperties.summary = summary === disabledSummaryText ? '' : summary;
      callSummaryOptionalProperties.isSummaryManuallyEdited = isSummaryManuallyEdited;

      if (Boolean(smartSummaryId)) {
        callSummaryOptionalProperties.smartSummaryId = smartSummaryId;
      }

      if (Boolean(includeSummaryInEmrNote)) {
        callSummaryOptionalProperties.includeSummaryInEmrNote = includeSummaryInEmrNote;
      }
    }

    const callObject: ICall = {
      id,
      callDuration,
      doctorId: userStore.currentDoctor.id,
      patientId: patient.id,
      note,
      lastReportId: lastReport ? lastReport.id : null,
      patientInitiated,
      createdAt: new Date(),
      updatedAt: null,
      durationIntervals,
      auditTrail,
      callReasons,
      topicsDiscussed,
      info: getCallInfo(patient, constantsStore.symptomsMap),
      orderedPathwayAnswers,
      isDraft,
      ticketIds,
      documentId: null,
      clinician,
      homeCareInstructions,
      ...callSummaryOptionalProperties,
      ...(isDraft && { homeCareInstructionsDraft })
    };

    return new Call(callObject);
  };

  return prepareCall;
};
