// @ts-strict-ignore
import { ChangeEvent, MouseEvent } from 'react';

import { css, Grid } from '@mui/material';

import { styled } from '@mui/material/styles';
import { Input } from 'reactstrap';

import { Testable } from 'utils/TypeUtils';

import { TextButton } from 'components/UIkit/atoms/Button';

import './ResettableInput.scss';

interface IResettableInputProps extends Testable {
  value: any;
  label: string;
  isRequired?: boolean;
  error?: boolean;
  errorMessage?: string;
  isEditingEnabled: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onResetClicked: () => void;
  disabled?: boolean;
  validate?: (value: string) => boolean;
}

const ResettableInput = (props: IResettableInputProps) => {
  const onResetClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    props.onResetClicked();
  };

  return (
    <div
      className={`resettable-input ${props.error ? 'error' : ''} ${
        props.disabled ? 'disabled' : ''
      }`}
    >
      <div className="input-header">{`${props.isRequired ? '* ' : ''}${props.label}`}</div>
      <div className="input-area">
        <Input
          className={`${props.isEditingEnabled ? '' : 'uneditable'}`}
          type="text"
          value={props.value}
          onChange={props.onChange}
          disabled={props.disabled || !props.isEditingEnabled}
          data-test-hook={props.testHook}
        />

        <ButtonWrapper container alignItems="center">
          <TextButton
            onClick={onResetClick}
            disabled={props.isEditingEnabled || props.disabled}
            display="block"
          >
            Reset
          </TextButton>
        </ButtonWrapper>

        <p className="error-text">{props.errorMessage}</p>
      </div>
    </div>
  );
};

const ButtonWrapper = styled(Grid)(
  ({ theme }) => css`
    position: absolute;
    right: ${theme.spacing(16)};
    top: 0;
    bottom: 0;
    margin: 0;
    width: fit-content;
  `
);

export default ResettableInput;
