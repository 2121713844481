// @ts-strict-ignore
import React, { useCallback, useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import { trackActivationSortByAnalyticsEvent } from 'analytics/events/sort-by';

import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import PatientsFetcher from 'fetchers/PatientsFetcher';

import { formatDate } from 'utils/DateUtils';

import { getCurrentSortByUrl } from 'utils/urlUtils';

import Patient, { IPatientUpdateableFields } from 'models/Patient';

import ScheduledProtocol, { ProtocolType } from 'models/ScheduledProtocol';

import { useNavPagination } from 'hooks/useNavPagination';

import { PatientFilters } from 'views/Filters/filters.types';
import { PatientsActivationPageFilters } from 'views/Filters/PatientsActivationPageFilters';

import ScheduledProtocolModal from 'views/Modals/ScheduledProtocolModal';
import { IProtocolOption } from 'views/Widgets/ReportPotocolInput';

import FixedLoader from 'components/Loaders/FixedLoader';

import { PatientDetailsCell } from 'components/Patient/Table/PatientDetailsCell';
import { PatientProtocolPickerCell } from 'components/Patient/Table/PatientProtocolPickerCell';
import { PatientProviderCell } from 'components/Patient/Table/PatientProviderCell';

import { PatientsActivationCell } from 'components/Patient/Table/PatientsActivationCell';

import { TextCell } from 'components/Patient/Table/TextCell';

import {
  defaultDatePropertyCompare,
  defaultNumberStringPropertyCompare,
  defaultStringPropertyCompare,
  PagedTable,
  PaginationLocation,
  SortOptions,
  TableCellParams,
  TableColumn
} from 'components/Table';

import './PatientActivation.scss';

export const PATIENT_ACTIVATION_LIST_FILTERS_LOCAL_STORAGE_KEY = '_patientsActivationPageFilters';

interface Row {
  patient: Patient;
  name: string;
  mrn: string;
  dateOfBirth: string;
  provider: string;
  invited: string;
  mainProtocol: ScheduledProtocol;
  reportAction: string;
  sex: number;
  activationButton: string;
  hasMobile: boolean;
}

const EmptyTableView = () => (
  <div className="no-results text-align-center">
    No patients are awaiting activation with the selected filters
  </div>
);

const AWAITING_ACTIVATION_PAGE_SIZE = 50;
const PatientsActivation = observer(() => {
  const { providersStore } = useStores();
  const [isLoadingProtocol, setIsLoadingProtocol] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<PatientFilters>({
    searchTerm: '',
    providers: null,
    locations: null
  });

  const [protocolScheduleModalOpen, setProtocolScheduleModalOpen] = useState(false);
  const [protocol, setProtocol] = useState(null);
  const [protocolType, setProtocolType] = useState(null);
  const [errorRowsIndexes, setErrorRowsIndexes] = useState([]);
  const [lastErrorRowIndex, setLastErrorRowIndex] = useState(-1);
  const [awaitingActivationPatients, setAwaitingActivationPatients] = useState<Patient[] | null>(
    null
  );
  const [totalPages, setTotalPages] = useState(0);
  const [activePatient, setActivePatient] = useState<Patient | null>(null);
  const [isFetchingPatients, setIsFetchingPatients] = useState<boolean>();

  const { currentPageByUrl } = useNavPagination();
  const sorting = getCurrentSortByUrl();
  let sortBy: string;
  let sortAsc: boolean;
  if (sorting) {
    sortBy = sorting.sortBy;
    sortAsc = sorting.sortAsc;
  }
  const fetchAwaitingActivationPatients = useCallback(async () => {
    const { searchTerm, locations, providers } = selectedFilters;
    const query = {
      searchTerm,
      providers: providers?.map((provider) => provider.value.id),
      locations: locations?.map((location) => location.value),
      pageNumber: currentPageByUrl,
      recordsPerPage: AWAITING_ACTIVATION_PAGE_SIZE,
      sortBy: sortBy,
      sortAsc: sortAsc
    };
    try {
      setIsFetchingPatients(true);
      const response = await PatientsFetcher.fetchAwaitingActivationPatients(query);
      setAwaitingActivationPatients(response.patients);
      setTotalPages(response.totalPages);
    } finally {
      setIsFetchingPatients(false);
    }
  }, [selectedFilters, currentPageByUrl, sortBy, sortAsc]);

  useEffect(
    function fetchPatients() {
      fetchAwaitingActivationPatients();
    },
    [fetchAwaitingActivationPatients]
  );

  const patients = awaitingActivationPatients || [];

  const handleFiltersChange = useCallback((newFilters: PatientFilters) => {
    setSelectedFilters({ ...newFilters });
  }, []);

  const createNewProtocol = (type: ProtocolType) => {
    const newProtocol = new ScheduledProtocol();
    newProtocol.type = type;
    return newProtocol;
  };

  const handleProtocolSubmitted = async (submittedProtocol: ScheduledProtocol) => {
    try {
      setIsLoadingProtocol(true);
      submittedProtocol.type = protocol.type;

      // TODO: remove this when we have a better way to update the patient
      // seems like the only fields really required here are locale and protocol
      const updateFields: IPatientUpdateableFields = {
        firstName: activePatient.firstName,
        lastName: activePatient.lastName,
        phone: activePatient.phone,
        phoneType: activePatient.phoneType,
        dateOfBirth: activePatient.dateOfBirth,
        remoteMonitoringConsent: activePatient.remoteMonitoringConsent,
        copayConsentGiven: activePatient.copayConsentGiven,
        patientReadTerms: activePatient.patientReadTerms,
        sex: activePatient.sex,
        mrn: activePatient.mrn,
        locale: activePatient.locale,
        protocol: submittedProtocol,
        optOut: activePatient.optOut,
        location: activePatient.location,
        enrollmentStatus: activePatient.enrollmentStatus,
        financialAssistance: activePatient.financialAssistance,
        providerId: activePatient.providerId,
        tags: activePatient.tags
      };

      await PatientsFetcher.updatePatient(activePatient.id, updateFields, null);
      await fetchAwaitingActivationPatients();
    } finally {
      reset();
    }
  };

  const reset = () => {
    setProtocolScheduleModalOpen(false);
    setIsLoadingProtocol(false);
    setProtocol(null);
    setProtocolType(null);
    setActivePatient(null);
  };

  const onProtocolClicked = (selectedProtocol: IProtocolOption, patient: Patient) => {
    const { mainScheduledProtocol: scheduledProtocol } = patient;

    let editedProtocol;
    if (scheduledProtocol) {
      editedProtocol = scheduledProtocol.copyWith({
        type: selectedProtocol.type
      });
    } else {
      editedProtocol = createNewProtocol(selectedProtocol.type);
    }
    editedProtocol.patientId = patient.id;
    setProtocol(editedProtocol);
    setProtocolType(editedProtocol.type);
    setProtocolScheduleModalOpen(true);
    setActivePatient(patient);
  };

  const onDisabledActivateButtonClick = (rowErrorIndex: number) => {
    if (errorRowsIndexes.includes(rowErrorIndex)) {
      return;
    }

    setErrorRowsIndexes([...errorRowsIndexes, rowErrorIndex]);
    setLastErrorRowIndex(rowErrorIndex);
  };

  const columns: TableColumn<Row>[] = [
    {
      Header: 'Patient',
      accessor: 'name',
      width: 200,
      sortType: defaultStringPropertyCompare('name'),
      Cell: (cellParams: TableCellParams<Row>) => (
        <PatientDetailsCell
          name={cellParams.row.original.name}
          to={`/patient/${cellParams.row.original.patient.id}`}
        />
      )
    },
    {
      Header: 'MRN',
      accessor: 'mrn',
      width: 140,
      sortType: defaultNumberStringPropertyCompare('mrn'),
      Cell: (cellParams: TableCellParams<Row>) => <TextCell text={cellParams.row.original.mrn} />
    },
    {
      Header: 'DOB',
      accessor: 'dateOfBirth',
      width: 140,
      sortType: defaultDatePropertyCompare('dateOfBirth'),
      Cell: (cellParams: TableCellParams<Row>) => (
        <TextCell text={cellParams.row.original.dateOfBirth} />
      )
    },
    {
      Header: 'Sex',
      accessor: 'sex',
      width: 140,
      Cell: (cellParams: TableCellParams<Row>) => (
        <TextCell text={Patient.getSexText(cellParams.row.original.sex)} />
      )
    },
    {
      Header: 'Invited',
      accessor: 'invited',
      width: 140,
      defaultSort: SortOptions.desc,
      sortType: defaultDatePropertyCompare('invited'),
      Cell: (cellParams: TableCellParams<Row>) => (
        <TextCell text={cellParams.row.original.invited} />
      )
    },
    {
      Header: 'Providers',
      accessor: 'provider',
      width: 140,
      Cell: (cellParams: TableCellParams<Row>) => (
        <PatientProviderCell providerText={cellParams.row.original.provider} />
      )
    },
    {
      disableSortBy: true,
      Header: (
        <Grid container justifyContent="end" width="100%">
          Report Protocol
        </Grid>
      ),
      accessor: 'mainProtocol',
      width: 170,
      Cell: (cellParams: TableCellParams<Row>) => (
        <PatientProtocolPickerCell
          error={errorRowsIndexes.includes(cellParams.row.index)}
          shakyError={lastErrorRowIndex === cellParams.row.index}
          hasMobile={cellParams.row.original.hasMobile}
          patient={cellParams.row.original.patient}
          scheduledProtocol={cellParams.row.original.mainProtocol}
          onProtocolSelected={onProtocolClicked}
        />
      )
    },
    {
      disableSortBy: true,
      accessor: 'activationButton',
      width: 170,
      Cell: (cellParams: TableCellParams<Row>) => (
        <PatientsActivationCell
          patient={cellParams.row.original.patient}
          scheduledProtocol={cellParams.row.original.mainProtocol}
          onActivateInvalid={() => onDisabledActivateButtonClick(cellParams.row.index)}
          onActivateSuccess={fetchAwaitingActivationPatients}
        />
      )
    }
  ];

  const rows: Row[] = patients.map((patient: Patient) => {
    const provider = providersStore.getProviderById(patient.providerId);
    return {
      patient,
      name: patient.fullName,
      mrn: patient.mrn,
      dateOfBirth: patient.formattedDateOfBirth || '',
      provider: provider ? provider.name : '',
      invited: formatDate(patient.remoteMonitoringConsent, 'MM/DD/YYYY'),
      mainProtocol: patient.mainScheduledProtocol,
      reportAction: '',
      sex: patient.sex,
      activationButton: '',
      hasMobile: patient.hasMobilePhone
    };
  });

  const isLoading = !awaitingActivationPatients || isFetchingPatients;

  return (
    <div className="limited-width dashboard-view-container">
      {protocolScheduleModalOpen && (
        <ScheduledProtocolModal
          onScheduledProtocolSubmitted={handleProtocolSubmitted}
          onScheduledProtocolCanceled={reset}
          isOpen
          type={protocolType}
          protocol={protocol}
          isLoading={isLoadingProtocol}
        />
      )}
      <div className="animated fadeIn dashboard-view">
        <h3 className="bold">Patients Awaiting Activation</h3>
        <div className="filters-row">
          <PatientsActivationPageFilters
            filters={selectedFilters}
            onFilterChange={handleFiltersChange}
            localStorageKey={PATIENT_ACTIVATION_LIST_FILTERS_LOCAL_STORAGE_KEY}
          />
        </div>
        {isLoading && <FixedLoader />}
        <PagedTable<Row>
          columns={columns}
          rowData={rows}
          emptyTableView={<EmptyTableView />}
          onManualSort={trackActivationSortByAnalyticsEvent}
          paginationLocation={[PaginationLocation.TOP]}
          totalPages={totalPages}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
});

export { PatientsActivation };
export default PatientsActivation;
