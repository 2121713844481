import { TicketSectionId, WorkQueueTab } from 'views/WorkQueue/WorkQueue.types';

export const workQueueTestSelectors = {
  page: 'work-queue-page',
  refreshDataButton: 'refresh-data-button',
  tab: (tab: WorkQueueTab) => `work-queue-${tab}-tab`,
  sectionTopPaginatorNextButton: (sectionName: TicketSectionId) =>
    `${sectionName}-paginator-top-next-button`,
  sectionTopPaginatorLastButton: (sectionName: TicketSectionId) =>
    `${sectionName}-paginator-top-last-button`,
  sectionTopPaginatorPreviousButton: (sectionName: TicketSectionId) =>
    `${sectionName}-paginator-top-previous-button`,
  sectionTopPaginatorFirstButton: (sectionName: TicketSectionId) =>
    `${sectionName}-paginator-top-first-button`,
  openItemsTab: {
    container: 'work-queue-open-items-view',
    filterSetsSelectMenu: 'filter-sets-select',
    filterSetsSelectLabel: 'filter-sets-select-label',
    filterSetsMenuOptions: {
      setOption: (filterSetId: number) => `filter-sets-select-option-${filterSetId}`,
      setOptionEdit: function (filterSetId: number) {
        return `${this.setOption(filterSetId)}-edit`;
      },
      saveAsOption: 'filters-sets-select-save-as-option',
      clearAllFiltersOption: 'filter-sets-select-clear-all-filters-option'
    },
    filtersAbandonChangesDialog: 'filters-sets-abandon-changes-dialog',
    filterSetModal: {
      container: 'filters-sets-modal',
      nameInput: 'filters-sets-modal-name-input',
      isSharedToggle: 'filters-sets-modal-is-shared-toggle',
      submitButton: function () {
        return `${this.container}-submit-button`;
      },
      filterSetDeleteDialog: 'filter-set-delete-dialog'
    }
  },
  resolvedItemsTab: {
    container: 'work-queue-resolved-items-view'
  }
};
