import {
  EventHandler,
  FC,
  ForwardedRef,
  forwardRef,
  ForwardRefExoticComponent,
  KeyboardEvent
} from 'react';

import { Checkbox as MuiCheckbox, css } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Testable } from 'utils/TypeUtils';

import Icon from 'components/Icons/Icon';

export interface CheckboxProps extends Testable {
  disabled?: boolean;
  error?: boolean;
  id?: string;
  name?: string;
  variant?: 'primary' | 'secondary' | 'tertiary';
  checked?: boolean;
  indeterminate?: boolean;
  onChange?: EventHandler<any>;
  onKeyDown?: (event: KeyboardEvent<HTMLButtonElement>) => void;
  readonly?: boolean;
  ref?: ForwardedRef<any>;
}

export const Checkbox: FC<CheckboxProps> = forwardRef(
  (
    {
      error,
      name,
      variant = 'primary',
      disabled,
      id,
      onKeyDown,
      onChange,
      checked,
      indeterminate,
      readonly,
      testHook
    },
    ref
  ) => (
    <StyledContainer
      data-test-hook={testHook}
      id={id}
      name={name}
      readOnly={readonly}
      disabled={disabled}
      checked={checked}
      indeterminate={indeterminate}
      onChange={onChange}
      ref={ref}
      icon={<StyledIconContainer variant={variant} />}
      checkedIcon={
        <StyledIconContainer variant={variant}>
          <Icon.CheckMark
            height={variant === 'tertiary' ? 8 : 12}
            width={variant === 'tertiary' ? 8 : 12}
          />
        </StyledIconContainer>
      }
      indeterminateIcon={
        <StyledIconContainer variant={variant}>
          <Icon.CheckIndeterminate />
        </StyledIconContainer>
      }
      color={error ? 'error' : 'primary'}
      size="small"
      onKeyDown={onKeyDown}
      variant={variant}
    />
  )
) as ForwardRefExoticComponent<CheckboxProps>;

const StyledContainer = styled(MuiCheckbox)<{ variant: 'primary' | 'secondary' | 'tertiary' }>(
  ({ theme, variant }) => {
    const borderAndBackgroundColor =
      variant === 'tertiary' ? theme.palette.secondary.main : theme.palette.primary.main;

    const borderAndBackgroundHoverColor =
      variant === 'tertiary' ? theme.palette.secondary.dark : theme.palette.primary.dark;

    return css`
      &:hover,
      &:focus-within {
        div {
          background-color: ${theme.palette.natural.hover};
          border-color: ${borderAndBackgroundHoverColor};
        }
      }

      &.Mui-checked,
      &.MuiCheckbox-indeterminate {
        div {
          border-color: ${borderAndBackgroundColor};
          background-color: ${borderAndBackgroundColor};
        }

        &:hover {
          div {
            border-color: ${borderAndBackgroundHoverColor};
            background-color: ${borderAndBackgroundHoverColor};
          }
        }
      }
    `;
  }
);

const StyledIconContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'variant'
})<{ variant: 'primary' | 'secondary' | 'tertiary' }>(({ theme, variant }) => {
  let borderColor = theme.palette.primary.main;

  if (variant === 'secondary') {
    borderColor = theme.palette.natural.border;
  } else if (variant === 'tertiary') {
    borderColor = theme.palette.secondary.main;
  }

  return css`
    position: relative;
    width: ${variant === 'tertiary' ? '16px' : '20px'};
    height: ${variant === 'tertiary' ? '16px' : '20px'};
    background-color: ${theme.palette.natural.white};
    border: solid 1px ${borderColor};
    border-radius: ${variant === 'tertiary' ? theme.borderRadius.xSmall : theme.borderRadius.small};
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: ${theme.palette.natural.white};

      path {
        stroke: none;
      }
    }
  `;
});
