import { REASON_TYPE } from './reasons.const';
const BASE_URL = '/api/v1';
const BASE_URL_V2 = '/api/v2';

export const API_URLS = {
  SERVER_VERSION: '',
  CLINICIAN_ALERTS_V1: `${BASE_URL}/clinicianAlerts`,
  CLINICIAN_ALERTS: `${BASE_URL_V2}/clinician-alerts`,
  SEND_SUPPORT_REQUEST: `${BASE_URL_V2}/doctor/contact-support`,
  REMOVE_ALERT: (id: number) => `${BASE_URL_V2}/clinician-alerts/${id}`,
  UPDATE_ALERT: (id: number) => `${BASE_URL_V2}/clinician-alerts/${id}`,

  CALLS: `${BASE_URL}/calls`,
  DISCONNECT_DRAFTS_FROM_TICKETS: `${BASE_URL}/calls/disconnect-drafts`,
  SAVE_PATIENT_CALL: (id: number) => `${BASE_URL}/patient/${id}/calls`,
  UPDATE_SAVED_CALL: (callId: number) => `${BASE_URL}/calls/${callId}`,
  CREATE_PATIENT_DRAFT_CALL: (id: number) => `${BASE_URL}/patient/${id}/calls/draft`,
  UPDATE_PATIENT_DRAFT_CALL: (patientId: number, callId: number) =>
    `${BASE_URL}/patient/${patientId}/calls/draft/${callId}`,
  DELETE_PATIENT_DRAFT_CALL: (patientId: number, callId: number) =>
    `${BASE_URL}/patient/${patientId}/calls/draft/${callId}`,
  VALIDATE_CALL_INTERVAL: (patientId: number) =>
    `${BASE_URL}/patient/${patientId}/calls/overlapping`,
  GENERATE_CALL_SMART_SUMMARY: `${BASE_URL_V2}/calls/smart-summary`,

  PATHWAY_TEMPLATES: `${BASE_URL_V2}/pathway-templates`,
  PATHWAY_BASIC_INFO: `${BASE_URL_V2}/pathway-templates/basic-info`,
  LIGHTWEIGHT_PATHWAY: `${BASE_URL_V2}/pathway-templates/lightweight-pathway`,
  REGIMEN_TEMPLATES: `${BASE_URL_V2}/regimens`,
  ICD_CATEGORIES: (value: string) => `${BASE_URL}/icdCategories/${value}`,
  YES_NO_QUESTIONS: `${BASE_URL}/yesNoQuestions`,

  OPERATOR_TICKET_TYPES: `${BASE_URL_V2}/operator-ticket-types`,
  OPERATOR_TICKET_SUBTYPE: (id: number) => `${BASE_URL_V2}/operator-ticket-types/${id}`,
  OPERATOR_TICKET_EDIT_ALLOWANCE: (id: number) =>
    `${BASE_URL_V2}/operator-ticket-types/${id}/allow-new-tickets`,

  PROVIDERS: `${BASE_URL_V2}/providers`,

  LOCATIONS: `${BASE_URL_V2}/locations`,
  REGIONS: `${BASE_URL_V2}/locations/regions`,
  UPDATE_LOCATION: (locationId: number) => `${BASE_URL}/locations/${locationId}`,

  DEPARTMENTS: `${BASE_URL_V2}/institution/departments`,
  INSTITUTIONS: `${BASE_URL_V2}/institution/institutions`,

  OPT_OUT_REASONS: `${BASE_URL}/optOutReasons`,
  UPDATE_OPT_OUT_REASON: (reasonId: number) => `${BASE_URL_V2}/opt-out/${reasonId}`,
  REMOVE_OPT_OUT_REASON: (id: number) => `${BASE_URL}/optOutReasons/${id}`,

  AWAITING_ACTIVATION_PATIENTS: `${BASE_URL_V2}/patients/awaiting-activation`,
  PATIENT_EPISODES: (id: number) => `${BASE_URL_V2}/patients/${id}/episodes`,
  PATIENT_EPISODE_END_REASONS: `${BASE_URL_V2}/episode-end-reasons`,
  UPDATE_PATIENT_END_EPISODE_REASON: (id: number) => `${BASE_URL_V2}/episode-end-reasons/${id}`,
  DELETE_PATIENT_END_EPISODE_REASON: (id: number) => `${BASE_URL_V2}/episode-end-reasons/${id}`,
  END_PATIENT_EPISODE: (patientId: number, episodeId: number) =>
    `${BASE_URL_V2}/patients/${patientId}/episodes/${episodeId}/end`,
  UPDATE_PATIENT_EPISODE: (patientId: number, patientEpisodeId: number) =>
    `${BASE_URL_V2}/patients/${patientId}/episodes/${patientEpisodeId}`,
  PATIENT: (id: number) => `${BASE_URL_V2}/patients/${id}`,
  UPDATE_PATIENT: (id: number) => `${BASE_URL_V2}/patients/${id}`,
  INVITE_PATIENT: `${BASE_URL_V2}/patients/invite`,
  SEARCH_PATIENTS: `${BASE_URL_V2}/patients/search`,
  SEARCH_GLOBAL_PATIENTS: `${BASE_URL_V2}/patients/global-search`,
  ADVANCED_SEARCH_PATIENTS: `${BASE_URL_V2}/patients/advanced-search`,
  EMR_PATIENT: (emrPatientId: string) => `${BASE_URL_V2}/patients/fromEmr/${emrPatientId}`,
  GENERATE_FAKE_REPORTS: `/cron/dailySummaries`,
  ACTIVATE_PATIENT: (id: number) => `${BASE_URL}/patient/${id}/activate`,
  SCHEDULED_PROTOCOL: (id: number) => `${BASE_URL}/patient/${id}/scheduleProtocol`,
  ADD_PATIENT_CONTACT: (id: number) => `${BASE_URL}/patient/${id}/contacts`,
  UPDATE_PATIENT_CONTACT: (patientId: number, contactId: number) =>
    `${BASE_URL}/patient/${patientId}/contacts/${contactId}`,
  DELETE_PATIENT_CONTACT: (patientId: number, contactId: number) =>
    `${BASE_URL}/patient/${patientId}/contacts/${contactId}`,
  CREATE_PATIENT_FROM_EMR: `${BASE_URL}/patient/fromEmr`,
  REQUEST_REPORT: `${BASE_URL}/patientCommunication/requestReport`,
  SNOOZE_PATIENT: `${BASE_URL}/patientCommunication/snooze`,
  SEND_SMS_TO_PATIENT: (patientId: number) =>
    `${BASE_URL_V2}/patient-communicator/${patientId}/send-sms`,
  UPDATE_MESSAGING_CONSENT: (patientId: number) =>
    `${BASE_URL_V2}/patient-communicator/${patientId}/messaging-consent`,
  SEND_HOMECARE_INSTRUCTIONS: (patientId: number) =>
    `${BASE_URL_V2}/patient-communicator/${patientId}/homecare-instructions`,

  CHECK_SESSION: `${BASE_URL}/checkSession`,

  INSTITUTION_SETTINGS: `${BASE_URL_V2}/institution-settings`,
  INSTITUTION_METADATA: `${BASE_URL_V2}/institution/metadata`,

  TICKETS: `${BASE_URL_V2}/tickets/operator-ticket`,
  UPDATE_TICKET: (id: number) => `${BASE_URL_V2}/tickets/operator-ticket/${id}`,
  TICKETS_QUERY: `${BASE_URL}/tickets`,
  SEARCH_TICKETS: `${BASE_URL_V2}/tickets/search`,
  TICKET_DELETE: (id: number) => `${BASE_URL_V2}/tickets/${id}`,
  TICKET_CHECK_STATUS: (ticketId: number) => `${BASE_URL_V2}/tickets/check-status/${ticketId}`,
  REOPEN_TASK: (id: number) => `${BASE_URL_V2}/tickets/${id}/unresolve`,
  CALLS_QUERY: `${BASE_URL}/calls`,
  RESOLVE_TICKET: (id: number) => `${BASE_URL}/doctor/tickets/${id}/resolve`, // Deprecated
  RESOLVE_TICKETS: `${BASE_URL}/tickets/resolve`,
  ASSIGN_TICKET: (id: number) => `${BASE_URL_V2}/tickets/${id}/assign`,
  TICKET_HISTORY: (id: number) => `${BASE_URL_V2}/tickets/${id}/history`,

  COMMENTS: `${BASE_URL}/comments`,
  GET_COMMENTS: (commentableId: number, commentableType: string) =>
    `${BASE_URL_V2}/comments/${commentableId}/type/${commentableType}`,

  SIGN_UP: `${BASE_URL}/signup`,
  LOGIN: `${BASE_URL}/login`,
  LOGIN_WITH_SSO: `${BASE_URL}/login-sso`,
  LOGOUT: 'v2/logout',
  VERIFY_2FA_CODE: `${BASE_URL}/verify2FACode`,
  RESEND_2FA_CODE: `${BASE_URL}/resend2FACode`,
  CALL_WITH_2FA_CODE: `${BASE_URL}/callWith2FACode`,
  LOOKUP_PHONE_DETAILS: (phoneNumber: string) => `${BASE_URL}/phoneLookup/${phoneNumber}`,

  CLINICIANS: `${BASE_URL_V2}/clinicians`,
  SEARCH_CLINICIANS: `${BASE_URL_V2}/clinicians/search`,
  GET_CLINICIANS_BY_IDS: `${BASE_URL_V2}/clinicians/by-ids`,
  GET_OWN_PROFILE: `${BASE_URL_V2}/clinicians/get-own-profile`,
  DEACTIVATE_CLINICIAN: (clinicianId: number) => `${BASE_URL}/clinicians/${clinicianId}/deactivate`,
  ACTIVATE_CLINICIAN: (clinicianId: number) => `${BASE_URL}/clinicians/${clinicianId}/activate`,
  UPDATE_DOCTOR_DETAILS: (id: number) => `${BASE_URL}/doctors/${id}`,
  UPDATE_CLINICIAN_PASSWORD: (clinicianId: number) =>
    `${BASE_URL}/clinicians/${clinicianId}/changePassword`,

  LOG_AUDIT: `${BASE_URL}/logAudit`, // should be replaced by v2 audit endpoint
  AUDIT_PAGE_VIEW: `${BASE_URL_V2}/audit/page-view`,

  TASKS: `${BASE_URL_V2}/task-tickets`,
  TASKS_QUERY: `${BASE_URL_V2}/task-tickets/search`,
  UPDATE_TASK: (id: number) => `${BASE_URL_V2}/task-tickets/${id}`,
  UPDATE_TASKS: `${BASE_URL_V2}/task-tickets/bulk`,
  RESCHEDULE_TASKS: `${BASE_URL_V2}/task-tickets/bulk-reschedule`,
  CHANGE_TASKS_STATUS: `${BASE_URL_V2}/task-tickets/bulk-status-change`,
  CLUSTERED_TASKS: `${BASE_URL_V2}/task-tickets/clustered`,
  EPISODES: `${BASE_URL_V2}/episodes`,
  SEARCH_EPISODES: `${BASE_URL_V2}/episodes/search`,
  DELETE_EPISODE: (id: number) => `${BASE_URL_V2}/episodes/${id}`,
  UPDATE_EPISODE: (id: number) => `${BASE_URL_V2}/episodes/${id}`,

  TASK_TEMPLATES: `${BASE_URL_V2}/task-templates`,
  DELETE_TASK_TEMPLATE: (id: number) => `${BASE_URL_V2}/task-templates/${id}`,
  UPDATE_TASK_TEMPLATE: (id: number) => `${BASE_URL_V2}/task-templates/${id}`,
  DUPLICATE_TASK_TEMPLATE: (id: number) => `${BASE_URL_V2}/task-templates/${id}/duplicate`,

  TASKS_ROLE: `${BASE_URL_V2}/task-tickets-role`,
  UPDATE_TASK_ROLE: (id: number) => `${BASE_URL_V2}/task-tickets-role/${id}`,
  DELETE_TASK_ROLE: (id: number) => `${BASE_URL_V2}/task-tickets-role/${id}`,

  PATIENT_TAGS: `${BASE_URL_V2}/patient-tags`,
  UPDATE_TAG: (tagId: number) => `${BASE_URL_V2}/patient-tags/${tagId}`,
  DELETE_TAG: (tagId: number) => `${BASE_URL_V2}/patient-tags/${tagId}`,

  RESET_INSTITUTION: `${BASE_URL_V2}/qa/institution`,
  CLEAR_PATIENTS: `${BASE_URL_V2}/qa/patients`,
  CREATE_EMR_ROW: `${BASE_URL_V2}/qa/createEMR`,
  CREATE_CM_ROW: `${BASE_URL_V2}/qa/create-cm-row`,
  CREATE_DX_ROW: `${BASE_URL_V2}/qa/diagnosis`,
  ADJUST_PATIENT_TIMELINE: (patientId: number, moveHours: number) =>
    `${BASE_URL_V2}/qa/patientTimeline/${patientId}/${moveHours}`,
  CREATE_PROVIDER: `${BASE_URL_V2}/qa/provider`,
  ADD_TREATMENT: `${BASE_URL_V2}/qa/add-treatment`,
  ADD_ORAL_AUTO_PROTOCOL_PATIENT: `${BASE_URL_V2}/qa/add-oral-auto-protocol`,
  SET_AUTO_ORAL_PROTOCOL_FOR_PATIENT: (patientId: number) =>
    `${BASE_URL_V2}/qa/set-auto-oral-protocol-for-patient/${patientId}`,
  CREATE_LOCATION: `${BASE_URL_V2}/qa/location`,
  TRIGGER_REBRANDING: `${BASE_URL_V2}/qa/trigger-rebranding`,
  GET_MOBILE_TOKEN_FOR_PATIENT: (patientId: number) =>
    `${BASE_URL_V2}/qa/mobile-token/${patientId}`,
  GET_WEB_EPRO_TOKEN_FOR_PATIENT: (patientId: number) => `${BASE_URL_V2}/qa/web-epro/${patientId}`,
  GET_ENROLLMENT_TOKEN_FOR_PATIENT: (patientId: number) =>
    `${BASE_URL_V2}/qa/enrollment/${patientId}`,
  GENERATE_NOTIFICATIONS: `${BASE_URL_V2}/qa/generate-clinician-notifications`,
  ADJUST_PATIENT_AUTO_PROTOCOL: `${BASE_URL_V2}/qa/adjust-auto-protocol`,
  SET_PATIENT_DECEASED: (patientId: number) =>
    `${BASE_URL_V2}/qa/patients/${patientId}/set-deceased`,
  UPDATE_CYCLE(patientId: number) {
    return `${BASE_URL_V2}/questionnaires-answer/${patientId}/cycle-start`;
  },
  PATIENT_LIST: `${BASE_URL_V2}/qa/patient-list`,
  CREATE_CARE_INTERVAL: (patientId: number) => `${BASE_URL_V2}/care-interval/${patientId}`,
  UPDATE_CARE_INTERVAL: (patientId: number, intervalId: number) =>
    `${BASE_URL_V2}/care-interval/${patientId}/${intervalId}`,
  VALIDATE_CARE_INTERVAL: (patientId: number) =>
    `${BASE_URL_V2}/care-interval/${patientId}/validate`,
  CARE_TIME_DATA: (patientId: number) => `${BASE_URL_V2}/patients/${patientId}/care-time-data`,
  UPDATE_FEATURE_FINISHED: `${BASE_URL_V2}/doctor/feature_intro`,

  CARE_MANAGEMENT_UPDATE_STATUS: (patientId: number) =>
    `${BASE_URL_V2}/care-management/${patientId}`,
  CARE_MANAGEMENT_REPORT: `${BASE_URL_V2}/care-management/report`,
  CARE_MANAGEMENT_ENROLLED: `${BASE_URL_V2}/care-management/enrolled-patients`,
  CARE_MANAGEMENT_FILTER_DATA: `${BASE_URL_V2}/care-management/filter-data`,
  CARE_MANAGEMENT_PATIENT_DATA: (patientId: number) =>
    `${BASE_URL_V2}/care-management/${patientId}`,
  CARE_MANAGEMENT_SEND_BULK_INVITATION: `${BASE_URL_V2}/care-management/bulk-invite`,
  CARE_MANAGEMENT_CHECK_INVITE_PROGRESS: (sessionId: string) =>
    `${BASE_URL_V2}/care-management/check-invite/${sessionId}`,
  REASONS_BY_TYPE: (reasonsType: REASON_TYPE) => `${BASE_URL_V2}/reasons/${reasonsType}`,
  REASON_BY_ID: (reasonId: number, reasonType: REASON_TYPE) =>
    `${BASE_URL_V2}/reasons/${reasonType}/${reasonId}`,

  THOUGHT_SPOT_INTEGRATION: `${BASE_URL_V2}/integrations/toughtspot`,
  RESET_DEMO_ENVIRONMENT: `${BASE_URL_V2}/demo/reset`,

  NOTIFICATIONS: `${BASE_URL_V2}/clinician-notifications`,
  CHECK_NEW_NOTIFICATIONS: `${BASE_URL_V2}/clinician-notifications/unseen`,
  CHECK_UNREAD_NOTIFICATIONS: `${BASE_URL_V2}/clinician-notifications/unread`,
  MARK_ALL_NOTIFICATIONS_READ: `${BASE_URL_V2}/clinician-notifications/mark-all-read`,
  DELETE_ALL_NOTIFICATIONS: `${BASE_URL_V2}/clinician-notifications/delete`,
  WORK_QUEUE: `${BASE_URL_V2}/work-queue`,
  WORK_QUEUE_SECTION_STATUS: `${BASE_URL_V2}/work-queue/check-section-status`,
  BULK_REQUEST_REPORT: `${BASE_URL_V2}/work-queue/bulk-request-report`,

  PRACTICE_MANAGER_USERS: `${BASE_URL_V2}/practice-manager/users`,
  PATHWAY_BUILDER_ACTIVE_TEMPLATE: `${BASE_URL_V2}/pathway-builder/active-pathway-templates`,
  PATHWAY_BUILDER_VALIDATE_PATHWAY: `${BASE_URL_V2}/pathway-builder/validate`,
  PATHWAY_BUILDER_RECENT_VERSIONS: `${BASE_URL_V2}/pathway-builder/recent-versions`,
  PATHWAY_BUILDER_PUBLISH: `${BASE_URL_V2}/pathway-builder/publish`,
  PATHWAY_BUILDER_ACTIVATE: (id: number) => `${BASE_URL_V2}/pathway-builder/${id}/activate`,
  FILTER_SETS: `${BASE_URL_V2}/filter-sets`,
  GET_FILTER_SET_DATA: (id: number) => `${BASE_URL_V2}/filter-sets/${id}`,
  DELETE_FILTER_SET: (id: number) => `${BASE_URL_V2}/filter-sets/${id}`,
  UPDATE_FILTER_SET: (id: number) => `${BASE_URL_V2}/filter-sets/${id}`
};

export const API_LABELS = {
  CALLS: (ticketIds: number[]) => `${API_URLS.CALLS}/${ticketIds}`,
  BULK_RESOLVE_TICKETS: (ticketIds: number[]): string[] =>
    ticketIds.map((id) => API_LABELS.RESOLVE_TICKET(id)),
  RESOLVE_TICKET: (id: number) => `${API_URLS.RESOLVE_TICKETS}/${id}`,
  COMMENTS: (id: number) => `${API_URLS.COMMENTS}/${id}`,
  TASKS_BY_PATIENT: (patientId: number | number[]) =>
    `${API_URLS.TASKS_QUERY}/${JSON.stringify(patientId)}`,
  CLUSTERED_TASKS: (isDelta: boolean) =>
    isDelta ? `${API_URLS.CLUSTERED_TASKS}/delta` : API_URLS.CLUSTERED_TASKS,
  TASKS_QUERY: (isDelta: boolean) =>
    isDelta ? `${API_URLS.TASKS_QUERY}/delta` : API_URLS.TASKS_QUERY,
  TICKETS_BY_PATIENT: (patientId: number) => `${API_URLS.SEARCH_TICKETS}/${patientId}`,
  WQ_FETCH_SECTIONS_DATA_AFTER_TICKET_ACTION: (ticketId: number) =>
    `${API_URLS.WORK_QUEUE}/ticketId=${ticketId}`,
  WQ_FETCH_OVERDUE_SECTION: (patientId: number) => `${API_URLS.WORK_QUEUE}/patientId=${patientId}`
};
