// @ts-strict-ignore
import { FC } from 'react';

import { observer } from 'mobx-react';
import { sharedTestSelectors } from 'tests/shared/shared.selectors';

import { ExtendedTicket } from 'utils/TicketClusteringUtils';

import { TicketSectionId } from 'views/WorkQueue/WorkQueue.types';

import { Section } from './TicketsContainers.constants';
import TicketsSection from './TicketsSection';

interface Props {
  tickets: ExtendedTicket[];
  ticketRefsCallback?: (node: HTMLElement, ticketId: number) => void;
}

const TicketsSectionUrgent: FC<Props> = ({ tickets, ticketRefsCallback }) => (
  <TicketsSection
    section={Section.Urgent}
    tickets={tickets}
    ticketRefsCallback={ticketRefsCallback}
    testHook={sharedTestSelectors.ticketSection.container(TicketSectionId.UrgentPatientReports)}
  />
);

export default observer(TicketsSectionUrgent);
